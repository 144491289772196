import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function RevyfyApp() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Mobile Apps For You & Your Clients"
        desc="Manage your busy schedule from anywhere. Get booked on any device and give your clients an easy way to self-check in. Our Apps run on both Apple iOS and Android, so everyone can stay connected."
        img="./images/Fearures Pages imgs/revyfy-app/hero.png"
      />

      {/* Card */}
      <LeftImgCard
        heading="Run Your Business On The Go"
        desc="Revyfy Pro is a business management platform that fits in the palm of your hand. Scheduling, payment processing, marketing, forms management & reporting tools are fully integrated with our web-based software, so you can keep track of things from anywhere."
        img="./images/Fearures Pages imgs/revyfy-app/img1.webp"
      />

      <RightImgCard
        heading="Let Clients Book You Online"
        desc="Make it easy for clients to interact with you online. The Revyfy Client App not only enables them to book services & classes online, it also gives them access to your online store where they can purchase memberships, packages, gift certificates & products."
        img="./images/Fearures Pages imgs/revyfy-app/img2.webp"
      />

      <LeftImgCard
        heading="Automate Check-In"
        desc="No front desk staff? No problem! With the Revyfy Check-In App, your clients check themselves in for classes or regular access without the need for additional assistance. They can even fill out waivers & intake forms directly from the app."
        img="./images/Fearures Pages imgs/revyfy-app/img3.webp"
      />

      <RightImgCard
        heading="Build Your Business with a Branded App"
        desc="Stay top-of-mind with your clientele. Your Branded App encourages customers to engage exclusively with your business anytime, anywhere, from any device—no Wi-Fi required! Having your custom app on their home screen builds brand awareness & customer loyalty."
        img="./images/Fearures Pages imgs/revyfy-app/img4.webp"
      />

      {/* trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
