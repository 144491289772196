import React from "react";
import "./demoComponent.css";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function ContactDemoComponent() {
  return (
    <section className="contactDemoComponent">
    <div className="container py-5">
      <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
        <iframe 
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          src="https://www.youtube.com/embed/wfZ3YfsKv5c" 
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </div>   
    </div>
  </section>
  );
}
