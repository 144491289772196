import React from "react";
import "./fullWidthCard.css";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function FullWidthCard({
  smallHeading,
  mainHeading,
  desc,
  list,
  learnMoreLink,
  imgUrl,
}) {
  return (
    <div className="row p-0 my-5 fullWidthCard">
      <div className="col-md-6 ps-md-0 p-sm-0 fullWidthCard-img">
        <img src={imgUrl} alt="" />
      </div>
      <div className="col-md-6 fullWidthCard-content px-4">
        <h4 className="fullWidthCard-content-smallHeading ">{smallHeading}</h4>
        <h2 className="fullWidthCard-content-mainHeading">{mainHeading}</h2>
        <p className="fullWidthCard-content-desc">{desc}</p>
        <div className="fullWidthCard-content-list">
          {list.map((item, index) => {
            return (
              <div key={index} className="fullWidthCard-content-list-item">
                <CheckIcon className="list-item-icon" />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        {learnMoreLink ? (
          <div className="fullWidthCard-content-learnMore">
            <Link to={learnMoreLink}>
              <button className="btn jump-hover">
                <span>Learn More</span>
                <ChevronRightIcon />
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
