import React, { useState } from "react";
import "./pricingCard.css";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CallIcon from "@mui/icons-material/Call";

export default function PricingCard() {
  const [slidePosition, setSlidePosition] = useState("slide-1");
  const [selectedOption, setSelectedOption] = useState("Just Me");
  const [selectedValue, setSelectedValue] = useState(0);
  const values = ["Just Me", "2", "3", "4", "5", "6", "7+"];

  const toggleSlidePosition = () => {
    setSlidePosition(slidePosition === "slide-1" ? "slide-2" : "slide-1");
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedValue(values.indexOf(option));
  };

  return (
    <div className="pricingCard p-4">
      <div className={`pricingCard-sliding-btn `} onClick={toggleSlidePosition}>
        <div className={`slide ${slidePosition}`}>One Location</div>
        <div
          className={`slide ${
            slidePosition === "slide-1" ? "slide-2" : "slide-1"
          }`}
        >
          Multiple Location
        </div>
      </div>
      <div
        className={`oneLocation-content ${
          slidePosition === "slide-1" ? "show-slide" : ""
        }`}
      >
        <h3>Here's what you'll pay:</h3>
        <div className="oneLocation-content-price">
          <div className="currency">&pound;</div>
          <span id="selected-price">{30 + selectedValue * 10}</span>
          <span className="payment-duration">per month*</span>
        </div>
        <div className="tables-status">
          <p>
            {`${selectedValue + 1}${
              selectedValue + 1 === 7 ? " or more" : ""
            } bookable
            ${selectedValue + 1 > 1 ? " calendars" : " calendar"}`}
          </p>
        </div>
        <div className="selected-persons">
          <div
            className={`option ${
              selectedOption === "Just Me" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("Just Me")}
          >
            Just Me
          </div>
          <div
            className={`option ${selectedOption === "2" ? "selected" : ""}`}
            onClick={() => handleOptionClick("2")}
          >
            2
          </div>
          <div
            className={`option ${selectedOption === "3" ? "selected" : ""}`}
            onClick={() => handleOptionClick("3")}
          >
            3
          </div>
          <div
            className={`option ${selectedOption === "4" ? "selected" : ""}`}
            onClick={() => handleOptionClick("4")}
          >
            4
          </div>
          <div
            className={`option ${selectedOption === "5" ? "selected" : ""}`}
            onClick={() => handleOptionClick("5")}
          >
            5
          </div>
          <div
            className={`option ${selectedOption === "6" ? "selected" : ""}`}
            onClick={() => handleOptionClick("6")}
          >
            6
          </div>
          <div
            className={`option ${selectedOption === "7+" ? "selected" : ""}`}
            onClick={() => handleOptionClick("7+")}
          >
            7+
          </div>
        </div>
        <div className="cancelation-notice">
          <p>*Cancel anytime with no cancellation fees</p>
        </div>

        <div className="start-trail-btn">
          <Link to="/">
            <button className="btn">Start Free Trail</button>
          </Link>
        </div>
        <div className="contact-sales-btn">
          <Link to="/">
            <p className="btn jump-hover">
              Contact Sales <ChevronRightIcon />
            </p>
          </Link>
        </div>
      </div>
      <div
        className={`enterprise-content p-4 ${
          slidePosition === "slide-2" ? "show-slide" : ""
        }`}
      >
        <div className="enterprise-img">
          <img src="./images/enterprise.webp" alt="" />
        </div>
        <h3>Enterprise Business Software</h3>

        <div className="notice">
          <p>
            Go enterprise for Revyfy's powerful and intuitive multi-location
            platform with advanced admin features and security.
          </p>
        </div>
        <div className="call-us">
          <CallIcon className="call-us-icon" />
          Call us Now +44 20 45524 2924
        </div>
        <div className="contact-team-btn">
          <Link to="/">
            <button className="btn">Contact Enterprise Team</button>
          </Link>
        </div>
        <div className="learn-more">
          <Link to="/">
            <p className="btn jump-hover">
              Learn More <ChevronRightIcon />
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
