import React from "react";
import "./functionalityFeaturesOption.css";

export default function FunctionalityFeaturesOption({ text }) {
  return (
    <div className="functionalityFeaturesOption px-2">
      <div className="functionalityFeaturesOption-icon">
        <img src="./images/green-tick.svg" alt="." />
      </div>
      <p className="functionalityFeaturesOption-text">{text}</p>
    </div>
  );
}
