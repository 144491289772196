import React, { useState } from "react";
import "./chatButton.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatForm from "./ChatForm";

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="chat-container">
      {isChatOpen && (
        <div className="chat-box">
          <div className="chat-box-header">
            <MoreHorizIcon />
            <span>Chat with Sales!</span>
            <MinimizeIcon
              className="minimize-icon"
              onClick={() => setIsChatOpen(false)}
            />
          </div>
          <div className="chat-box-body">
            <ChatForm />
          </div>
        </div>
      )}
      <button className="chat-button" onClick={toggleChat}>
        <ChatBubbleOutlineRoundedIcon className="chat-icon" />
      </button>
    </div>
  );
};

export default ChatButton;
