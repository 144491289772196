import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import SignupForm from "../../components/Signup Form/SignupForm";

export default function SignUp() {
  return (
    <>
      <Navbar />
      <SignupForm />
    </>
  );
}
