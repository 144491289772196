import React from "react";
import "./securityInfo.css";

export default function SecurityInfo() {
  return (
    <div className="container py-5 securityInfo">
      <div className="row p-md-3 p-sm-2">
        <div className="col-md-5 securityInfo-img">
          <img src="./images/pricing-compliance.webp" alt="" />
        </div>
        <div className="col-md-7 securityInfo-content">
          <h2>Security to Keep Your Businesses Safe & Secure</h2>
          <p>
            We are assessed by qualified external auditors to be compliant with
            Requirements for Information Security Management Systems (ISO
            27001), Payment Card Industry Data Security Standard (PCI DSS),
            Trust Services Criteria (SOC 2) and effectiveness of Health
            Insurance Portability and Accounting Act (HIPAA) implementation.
          </p>
        </div>
      </div>
    </div>
  );
}
