import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import BusinessTypesHero from "../../../components/Business Types Hero/BusinessTypesHero";
import LeftToRightFeatureContainer from "../../../components/LeftToRightFeatureContainer/LeftToRightFeatureContainer";
import RightToLeftFeatureContainer from "../../../components/RightToLeftFeatureContainer/RightToLeftFeatureContainer";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import Trail from "../../../components/Trail/Trail";
import Footer from "../../../components/Footer/Footer";
import Customer from "../../../components/Customer/Customer";
import Plateforms from "../../../components/PlateForms/Plateforms";

export default function OnlineBooking() {
  return (
    <>
      <Navbar />

      <BusinessTypesHero
        smallHeading="ONLINE BOOKING SYSTEM"
        heading="Online Booking 24/7"
        desc="Transform your booking process with a fully customizable online booking system. Your clients can seamlessly schedule appointments or classes on their own time, from anywhere"
        img="./images/Fearures Pages imgs/online-booking/hero.png"
        time="hide"
      />

      <RightToLeftFeatureContainer
        smallHeading="ONLINE BOOKING"
        mainHeading="Online Appointment Settings"
        desc="Customize your online booking system to work for you & your staff"
        list={[
          "Accept all appointments or require them to be requested with flexibility to accept, reschedule, or deny",
          "Save credit cards on file for holding online appointments",
          "Set lead times to limit how soon your customer can make an appointment with you online",
          "Show your cancellation policy for clear communication",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img1.webp"
      />

      <div className="get-paid">
        <div className="container">
          <HeadingSection
            title="GET PAID"
            heading="Accept payments safely & securely"
            details="Get paid securely on any device, anywhere."
          />
          <div className="row gy-4 get-paid-payment-features">
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-booking/card1.webp"
                heading="Contactless Payments"
                text="Get paid via Apple Pay, Google Pay & other contactless payment methods
          when customers tap the Revyfy Contactless Credit Card Reader."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-booking/card2.webp"
                heading="Contactless Checkout"
                text="Customers can safely & securely check out and tip using Revyfy's Contactless Checkout."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-booking/card3.webp"
                heading="Card on File"
                text="Store your clients' cards on file for faster checkouts and charge recurring payments."
              />
            </div>
          </div>
        </div>
      </div>

      {/* Cards */}

      <LeftImgCard
        heading="Google & Apple Maps"
        desc="Expand your reach and get booked online easily when customers search your business on Google & Apple Maps."
        img="./images/Fearures Pages imgs/online-booking/img2.webp"
        smallImg="./images/Fearures Pages imgs/online-booking/map-icon.png"
      />

      <RightImgCard
        heading="Get Booked from Instagram, Facebook & Yelp"
        desc="Get booked easily, 24/7, through our social integrations with Facebook, Instagram & Yelp. Clients can view services, pricing & business information synced from your Revyfy account."
        img="./images/Fearures Pages imgs/online-booking/img3.webp"
        smallImg="./images/Fearures Pages imgs/online-booking/social-icon.png"
      />

      <RightToLeftFeatureContainer
        smallHeading="BOOKING WIDGET"
        mainHeading="Online Booking Widget"
        desc="Make booking online appointments accessible from any platform"
        list={[
          "Create widget types to embed on your website, generate it as a link, a pop-up, or have it open up in a new tab",
          "Your staff can create their own widget to book directly with them",
          "Customize what you want the widget to include like services, reviews, products & more",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img5.gif"
      />

      <LeftToRightFeatureContainer
        smallHeading="CUSTOMER WAITLIST"
        mainHeading="Add Customers to the Waitlist"
        desc="Customers can be added to a waitlist when your schedule is fully booked & never miss an opportunity to fill your calendar."
        list={[
          "Customers can be added or can add themselves to your waitlist through your online booking system",
          "Got a cancellation? Revyfy can fill the spot for you and your staff",
          "Our online booking system can take care of your waitlist for you with advanced settings to get you booked",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img4.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="BRANDED APP"
        mainHeading="Your Personalized App"
        desc="Brand your online booking journey with your very own app"
        list={[
          "All the same functions as the Revyfy app, but with your unique branding",
          "Customers can book online, shop your products, manage their profile & more",
          "Our in-house designers work with you to build it & watch your brand unfold in the Apple App Store & Google Play",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img6    .webp"
      />

      <LeftToRightFeatureContainer
        smallHeading="HOUSE CALLS"
        mainHeading="Mobile Business"
        desc="An on-the-go online booking system for your mobile business"
        list={[
          "Revyfy automatically adds travel time to the client's location",
          "A travel surcharge for your mobile services can be automatically added to your service pricing",
          "Get directions to your next appointment right from Revyfy via your computer, tablet or mobile device",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img7.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="REVYFY CUSTOMER APP"
        mainHeading="Revyfy App for Online Booking"
        desc="With over 5 million registered users, customers can download the Revyfy App to book their appointments online"
        list={[
          "Customers can download the free app to check on their appointments, book more appointments, and keep track of memberships & packages",
          "Customers can add appointments directly to their personal calendar from the Revyfy App",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img8.webp"
      />

      <LeftToRightFeatureContainer
        smallHeading="FORMS"
        mainHeading="Streamline your online booking process by requesting essential information through user-friendly forms"
        desc="An on-the-go online booking system for your mobile business"
        list={[
          "Collect important information by implementing mandatory forms when clients book appointments online",
          "Allow customers to upload photos, update credit card information & even sign intake or liability documents before the appointment",
        ]}
        imgUrl="./images/Fearures Pages imgs/online-booking/img9.webp"
      />

      {/* Customer Carousel */}
      <Customer
        smallHeading="ONLINE BOOKING SYSTEM TESTIMONIALS"
        heading="The Best Online Booking System Software"
      />

      {/* Plate Forms */}
      <Plateforms />

      {/* Trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
