import React, { useState } from "react";
import "./addOnFeatures.css";
import AddOnOption from "./AddOnOption";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AddOnFeatures() {
  const [showFeatures, setShowFeatures] = useState(false);

  return (
    <div className="container p-md-5 addOnFeatures">
      <div className="addOnFeatures-heading px-md-5">
        <h2>Optimize with Add-on Features</h2>
        <p>
          From live streaming to automated email & text marketing, we have
          powerful add-ons to run and grow your business.
        </p>
      </div>
      <div className="addOnFeatures-btn">
        <button
          onClick={() => {
            setShowFeatures(!showFeatures);
          }}
        >
          View All Add-On Features
          <span>
            <ExpandMoreIcon
              className={`addOnFeatures-btn-icon ${
                !showFeatures ? "addOnFeatures-btn-icon-rotate" : ""
              }`}
            />
          </span>
        </button>
      </div>
      <div
        className={`addOnFeatures-list ${
          showFeatures ? "p-md-5 p-sm-2 show-features" : ""
        }`}
      >
        <div className="row gy-4">
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
          <div className="col-12 col-md-6">
            <AddOnOption />
          </div>
        </div>
      </div>
    </div>
  );
}
