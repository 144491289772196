import React from "react";
import "./featuresPagesHero.css";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

export default function FeaturesPagesHero({ heading, desc, img }) {
  return (
    <section className="featuresHero">
      <div className="container-fluid py-5">
        <div className="row featuresHero-main">
          <div className="col-md-6 featuresHero-main-content">
            <h1>{heading}</h1>
            <p>{desc}</p>
            <div className="featuresHero-main-btns">
              <div className="featuresHero-main-trailBtn">
                <Link to="/pricing">
                  <button className="btn btn-dark jump-hover">
                    Start Free Trail
                  </button>
                </Link>
              </div>
              <div className="featuresHero-main-contactBtn">
                <Link to="/contact">
                  <button className="btn jump-hover">
                    Start Free Trail
                    <ChevronRightOutlinedIcon className="right-arrow" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 featuresHero-main-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
