import React from "react";
import "./payroll.css";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Payroll() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Revyfy Online Payroll Services for Your Small Business"
        desc="Choose the best online payroll services for your small business, no matter which structure you use to pay employees! Run payroll in minutes and simplify business payroll tax filings."
        img="./images/Fearures Pages imgs/payroll/hero.png"
      />
      {/* Cards sections */}
      <section className="payrollHeading py-5">
        <div className="container">
          <h2>Customisable Commission Payouts</h2>
          <p>
            Custom commission tiers give owners more control by ensuring payroll
            costs are directly tied to the money employees bring in. Easy-to-use
            tools allow you to create unlimited customised commission tiers, set
            specific commission rates, and pay employees for services, classes,
            & product sales using a flat amount or a percentage.
          </p>
        </div>
      </section>

      <RightImgCard
        heading="Unlimited Commission Tiers"
        desc="Use tiered commissions to encourage employees to sell more services and products. As performance increases, employees earn a higher commission rate. Create as many commission tiers as you want for service, class, & product sales."
        img="./images/Fearures Pages imgs/payroll/img1.webp"
      />

      <LeftImgCard
        heading="Customise Commission Rates"
        desc="Every service and product you sell is unique, and how you pay staff for them should be, too! Revyfy enables you to set specific commission tiers by service, class, or product, so you can focus employees on selling more of specific products or services. Set specific commission pay tiers for service, class, and product sales."
        img="./images/Fearures Pages imgs/payroll/img2.webp"
      />

      <RightImgCard
        heading="Flat-Rate or Percentage"
        desc="Small business owners can design your ideal payroll with hourly base pay and set commission rates or a sliding scale percent commission. Have more control over payroll costs by adjusting commission rates by specific employee, experience levels, sales goals, or specific retail products."
        img="./images/Fearures Pages imgs/payroll/img3.gif"
      />

      <LeftImgCard
        heading="Full-Service Payroll Management for Businesses of Any Size"
        desc="Revyfy online payroll software is fully customisable for small business owners, simplifying every aspect of paying your staff. From first time set-up to payday, Revyfy makes it easy to get started and even easier to run the payroll your business needs on a schedule that works for you. Revyfy gives you:"
        img="./images/Fearures Pages imgs/payroll/img4.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
