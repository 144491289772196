import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer ">
        <div
          className="container-fluid text-white  py-5 "
          style={{ backgroundColor: "#333333" }}
        >
          <div className="container ">
            <div className="row ">
              <div className="col-sm-6 col-lg-6 py-5">
                <h1 className="mb-2" style={{ fontSize: "18px" }}>
                  Download App
                </h1>
              
                <h2 style={{ fontSize: 18 }}>View All Revyfy Apps</h2>
              </div>
              <div className="col-sm-6 col-lg-6 footer-links">
                <div className="row py-5 ">
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <h1 className="fw-bold mb-3" style={{ fontSize: "16px" }}>
                      Get Started
                    </h1>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Pricing
                      </h2>
                    </Link>                  
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Point of Sale
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Salon App
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Spa App
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Fitness App
                      </h2>
                    </Link>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <h1 className="mb-3 fw-bold" style={{ fontSize: "16px" }}>
                      Company
                    </h1>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        About Us
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Contact Us
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Updates
                      </h2>
                    </Link>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <h1 className="mb-3 fw-bold" style={{ fontSize: "16px" }}>
                      Resources
                    </h1>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Support
                      </h2>
                    </Link>
                    <Link to="/" className="footer-link">
                      <h2
                        className="fw-normal mb-3"
                        style={{ fontSize: "16px" }}
                      >
                        Tutorials
                      </h2>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr style={{ border: "1px solid white" }} />
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6">
                {/* <img src="./images/logo-white.svg" alt="" width="110px" /> */}
              </div>
              <div className="col col-md-6 col-lg-6"></div>
            </div>
            <div className="row">
              <div className="col">
                <hr style={{ border: "1px solid white" }} />
              </div>
            </div>
            <div className="row gy-3 mb-5">
              <div className="col-md-6 d-flex align-items-center">
                <img src="./images/flag-uk.svg" alt="" className="me-2" />
                <span>United Kingdom</span>
              </div>
              <div className="col-md-6 d-flex justify-content-around ">
                <Link to="/" className="footer-link">
                  <h2 style={{ fontSize: "14px" }}>Privacy Policy</h2>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <h2 style={{ fontSize: "18px" }}>&copy; 2023 Revyfy, Limited</h2>
                <h2 style={{ fontSize: "18px" }}>
                  
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
