import React from "react";
import "./contactUsCard.css";
import { Link } from "react-router-dom";

export default function ContactUsCard({ icon, title, duration, itemsList }) {
  const handlePhoneIconClick = (event, phoneNumber) => {
    event.preventDefault();
    if (phoneNumber) {
      const url = `tel:${phoneNumber}`;
      window.location.href = url;
    }
  };
  const handleEmailIconClick = (event, email) => {
    event.preventDefault();
    if (email) {
      const mailtoUrl = `mailto:${email}`;
      window.location.href = mailtoUrl;
    }
  };

  return (
    <div className="contactUsCard p-4 jump-hover">
      <div className="contactUsCard-head">
        <div className="contactUsCard-head-icon">
          <img src={icon} alt="" />
        </div>
        <h3 className="contactUsCard-head-title">{title}</h3>
        <p className="contactUsCard-head-time">{duration}</p>
      </div>
      <div className="contactUsCard-body">
        <div className="list-group">
          {itemsList.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.linkTo}
                className="list-group-item list-group-item-action"
                onClick={(event) => {
                  if (item.phoneIcon) {
                    return handlePhoneIconClick(event, item.phoneNumber);
                  } else if (item.email) {
                    return handleEmailIconClick(event, item.email);
                  }
                }}
              >
                {item.phoneIcon ? (
                  <span className="list-group-icon" key={index}>
                    <img src="./images/blue-phone.svg" alt="" />
                  </span>
                ) : null}
                {item.text}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
