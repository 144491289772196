import React from "react";
import "./loginForm.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
export default function LoginForm() {
  return (
    <div className="container-fluid LoginForm">
      <div className="container">
        <div className="row pt-3">
          <div className="col-12 col-md-5 loginForm-img">
            <img src="./images/revyfy-screen-login-img.png" alt="" />
          </div>
          <div className="col-12 col-md-5 p-0 loginForm-form">
            <div className="loginForm-logo">
              <span>revyfy</span>
            </div>
            <div className="loginButtons px-4 py-2">
              <button className="loginButton facebook">
                <FacebookIcon className="icon" />
                Login with Facebook
              </button>
              <button className="loginButton google">
                <GoogleIcon className="icon" />
                Login with Google
              </button>
              <button className="loginButton apple">
                <AppleIcon className="icon" />
                Sign in with Apple
              </button>
            </div>
            <div className="or-divider">
              <div className="line"></div>
              <div className="or-text">or</div>
              <div className="line"></div>
            </div>
            <form>
              <div className="inputGroup">
                <MailOutlineIcon className="inputIcon" />
                <input type="text" placeholder="Username or Email" />
              </div>
              <div className="inputGroup">
                <HttpsOutlinedIcon className="inputIcon" />
                <input type="password" placeholder="Password" />
              </div>
              <div className="checkboxGroup">
                <label htmlFor="rememberMe">
                  <input type="checkbox" id="rememberMe" />
                  Remember me
                </label>
                <Link to="/forgot-password" className="forgot-link">
                  Forgot Password?
                </Link>
              </div>
              <button type="submit">Login</button>
            </form>
            <div className="signup">
              <span>Don't have an account?</span>
              <Link to="/signup" className="signup-link">
                Sign Up Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
