import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Membership() {
  return (
    <>
      {/* NavBar */}
      <Navbar />

      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Memberships"
        desc="Boost revenue by selling memberships in-house and online. Set up membership autopay to protect your recurring income. Have members check themselves in with the branded Check-In App."
        img="./images/Fearures Pages imgs/membership/hero.png"
      />

      {/* Cards sections */}
      <LeftImgCard
        heading="Create Recurring Income"
        desc="Save time and get paid faster by setting up automatic billing for your memberships. Choose to bill your customers weekly, monthly, quarterly or yearly."
        img="./images/Fearures Pages imgs/membership/img1.gif"
      />

      <RightImgCard
        heading="Add Membership Benefits"
        desc="Drive sales by building service, class or product discounts into your memberships. Or add reward points to a membership to encourage future purchases."
        img="./images/Fearures Pages imgs/membership/img2.webp"
      />

      <LeftImgCard
        heading=""
        desc="Sell memberships from your point of sale or online via the Revyfy Marketplace, your booking page, Facebook, Yelp & Instagram. Customise your Booking Widget to sell memberships from your own website or blog."
        img="./images/Fearures Pages imgs/membership/img3.webp"
      />
      <RightImgCard
        heading="Incentivize Membership Sales"
        desc="Incentivize future clients to buy a membership or package by offering free trials. Seal the deal by offering an exclusive promo code or Daily Deal for the membership discount."
        img="./images/Fearures Pages imgs/membership/img4.webp"
      />
      <LeftImgCard
        heading="Rethink Your Front Desk"
        desc="Clients can download the Revyfy Check-In App to check themselves in using an iPad at your point of sale. They can scan their phone, smartwatch, custom branded membership card or keychain card. Alternatively, they can also check in by simply entering a password."
        img="./images/Fearures Pages imgs/membership/img5.webp"
      />
      <RightImgCard
        heading="Family & Friends Share"
        desc="Create relationships between Revyfy customer accounts. Memberships and Packages can be shared with family & friends (if the business allows it). Customers can book for family & friends and share credit cards among members in a plan for quick & easy checkout. Pets can also be included in a family for services like grooming."
        img="./images/Fearures Pages imgs/membership/img6.gif"
      />
      <LeftImgCard
        heading="Let Your Brand Shine"
        desc="Display your business’s branding on your Check-In App, keychain cards & membership cards."
        img="./images/Fearures Pages imgs/membership/img7.webp"
      />

      {/* trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
