import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Forms() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Build Your Own Custom Forms"
        desc="Quickly select a template or build your custom form from scratch. Send legal waivers, intake forms & surveys to your customers and their responses will be automatically saved to their account."
        img="./images/Fearures Pages imgs/branded-app/hero.png"
      />
      {/* Cards sections */}

      <LeftImgCard
        heading="Drag & Drop"
        desc="With an easy drag & drop builder, you can create your own custom form and add specific question types like multiple choice, checkboxes, short answer & more."
        img="./images/Fearures Pages imgs/forms/img1.gif"
      />

      <RightImgCard
        heading="Upload Files to Customer Profiles"
        desc="When filling out a form, clients can now upload JPG, PNG & PDF files. This is a great way to store before-and-after photos, inspiration pics, documents & digital copies of your old paper forms online. All files will be automatically saved to the correct customer profile."
        img="./images/Fearures Pages imgs/forms/img2.webp"
      />
      <LeftImgCard
        heading="Capture Credit Cards"
        desc="Add the payment module to your form to instantly capture your customer's credit card information. Having customers’ cards on file will expedite the checkout process and make it easy to accept booking deposits & charge cancellation fees."
        img="./images/Fearures Pages imgs/forms/img3.webp"
      />
      <RightImgCard
        heading="Simplify Updating Customer Information"
        desc="Update customer info by adding the contact module to your form. If a customer makes any changes to their contact information, it will automatically update on their customer profile. This is also a great way to add a subscription list to your website."
        img="./images/Fearures Pages imgs/forms/img4.webp"
      />
      <LeftImgCard
        heading="Responses with Analytics"
        desc="Every question, every answer & any quantifiable data available is drawn to give you the insights you've always wanted."
        img="./images/Fearures Pages imgs/forms/img5.webp"
      />
      <RightImgCard
        heading="Link to Customer Profile"
        desc="Never misplace a form again. Ditch the paper waivers & physical SOAP notes and create digital forms that are automatically tied to your customers' profiles."
        img="./images/Fearures Pages imgs/forms/img6.webp"
      />
      <LeftImgCard
        heading="Integrate with the Check-In App"
        desc="Forms gives you the ability to seamlessly integrate your waivers and forms with the Check-In App. Your business can require waivers when signing up, joining a membership, or purchasing a package."
        img="./images/Fearures Pages imgs/forms/img7.webp"
      />
      <RightImgCard
        heading="Get Your Forms Filled"
        desc="Forms can be required for certain clients. Revyfy will automatically notify and remind your clients of required forms. Missing forms will also be shown with an icon on the Calendar screen."
        img="./images/Fearures Pages imgs/forms/img8.webp"
      />
      <LeftImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/forms/img6.webp"
      />
      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/forms/img6.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
