import React, { useState } from "react";
import "./chatForm.css";
import PermIdentityRoundedIcon from "@mui/icons-material/PermIdentityRounded";

const ChatForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [locations, setLocations] = useState("");
  const [categories, setCategories] = useState([]);

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCategories((prevCategories) => [...prevCategories, value]);
    } else {
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category !== value)
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or data processing here
    console.log("Submitted:", {
      name,
      email,
      phone,
      locations,
      categories,
    });
    // Reset form fields
    setName("");
    setEmail("");
    setPhone("");
    setLocations("");
    setCategories([]);
  };

  return (
    <div className="chatForm">
      <div className="logo">
        <PermIdentityRoundedIcon />
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="locations">Number of Locations</label>
            <select
              id="locations"
              name="locations"
              value={locations}
              onChange={(e) => setLocations(e.target.value)}
              required
            >
              <option value="">Select an option</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              {/* Add more options if needed */}
            </select>
          </div>
          <div className="form-group">
            <label>What category does your business fall under?</label>
            <div className="form-group-check">
              <label>
                <input
                  type="checkbox"
                  value="beauty"
                  checked={categories.includes("beauty")}
                  onChange={handleCategoryChange}
                />
                Beauty
              </label>
            </div>
            <div className="form-group-check">
              <label>
                <input
                  type="checkbox"
                  value="fitness"
                  checked={categories.includes("fitness")}
                  onChange={handleCategoryChange}
                />
                Fitness
              </label>
            </div>
            <div className="form-group-check">
              <label>
                <input
                  type="checkbox"
                  value="wellness"
                  checked={categories.includes("wellness")}
                  onChange={handleCategoryChange}
                />
                Wellness
              </label>
            </div>
            <div className="form-group-check">
              <label>
                <input
                  type="checkbox"
                  value="other"
                  checked={categories.includes("other")}
                  onChange={handleCategoryChange}
                />
                Other
              </label>
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ChatForm;
