import React from "react";
import "./home.css";
import HomeHero from "./HomeHero";
import Navbar from "../../components/Navbar/Navbar";
import Time from "../../components/Time/Time";
import Business from "../../components/Business/Business";
import Footer from "../../components/Footer/Footer";
import Features from "../../components/Features/Features";
import Opportunity from "../../components/Opportunity/Opportunity";
import Payments from "../../components/Payments/Payments";
import Customer from "../../components/Customer/Customer";
import Branding from "../../components/Branding";
import Plateforms from "../../components/PlateForms/Plateforms";
import Service from "../../components/Service/Service";
import Trail from "../../components/Trail/Trail";

const Home = () => {
  return (
    <>
      <Navbar />
      <HomeHero />
      <Time />
      <Business />
      <Features />
      <Opportunity />
      <Payments />
      <Customer
        smallHeading="NAIL SALON SOFTWARE & APP TESTIMONIALS"
        heading="The Best Nail Salon Business & App Software"
      />
      {/* <Plateforms /> */}
      {/* <Branding /> */}
      <Service />
      <Trail />
      <Footer />
    </>
  );
};

export default Home;
