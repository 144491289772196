import React from "react";
import "./accordionItem.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionItem({ headerText, contentText }) {
  return (
    <>
      <Accordion className="accordionItem">
        <AccordionSummary
          className="accordionItem-summary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="accordionItem-heading">
            {headerText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionItem-details">
          <Typography
            className="accordion-content"
            dangerouslySetInnerHTML={{ __html: contentText }}
          ></Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
