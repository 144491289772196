import React from "react";
import TextCardWithBtn from "../TextCardWithBtn/TextCardWithBtn";
import "./exploreMore.css";

export default function ExploreMore({ data }) {
  return (
    <section className="explore-more">
      <div className="container">
        <div className="explore-more-heading">
          <h3>EXPLORE MORE SALON SOFTWARE & APP FEATURES</h3>
          <h2>Do what you love, let Revyfy do the rest</h2>
        </div>
        <div className="row p-5 gy-5 explore-more-cards">
          {data ? (
            data.map((item, index) => {
              return (
                <TextCardWithBtn
                  heading={item.heading}
                  desc={item.desc}
                  cardLink={item.cardLink}
                  learnMorebtn={item.learnMorebtn}
                  key={index}
                />
              );
            })
          ) : (
            <>
              <TextCardWithBtn
                heading="Reminder Notifications"
                desc="Automated email, text & push notifications to remind or confirm
    appointments & more."
                cardLink="/notification"
              />
              <TextCardWithBtn
                heading="Client Management"
                desc="Store client info and look up appointments, product purchases, gift certificate package details & more."
                cardLink="/customer-tracking"
              />

              <TextCardWithBtn
                heading="Forms"
                desc="Use a template or create your own customized forms to send to clients. After clients are done, forms auto-save to their profile."
                cardLink="/forms"
              />
              <TextCardWithBtn
                heading="Email & Text Marketing"
                desc="Automate email & text marketing to connect with clients at scale."
                cardLink="/marketing"
              />
              <TextCardWithBtn
                heading="Contactless Check-In"
                desc="Clients check in from their mobile device, notifying providers of their arrival. A notification is sent to clients when the provider is ready."
                cardLink="/check-in-app"
              />
              <TextCardWithBtn
                heading="Reports"
                desc="View a dashboard to get real-time information at a glance or drill into the details of your business by running customizable reports."
                cardLink="/reports"
              />
              <TextCardWithBtn
                heading="Branded Website & App"
                desc="Strengthen your brand with your personalized Revyfy theme page, or have us build your own branded app."
                cardLink="/branded-app"
              />
              <TextCardWithBtn
                heading="Payroll"
                desc="Run payroll in minutes, create custom commission tiers, pay employees via direct deposit & more."
                cardLink="/payroll"
              />
              <TextCardWithBtn
                heading="Rent & Fees"
                desc="Schedule recurring rent & fees within the software and deduct commission from rent."
                cardLink="/automatic-rent-fees"
              />
              <TextCardWithBtn
                heading="Service & Class Menu"
                desc="Customize price, duration & points for each service and employees who offer them."
                cardLink="/service-class-menu"
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
