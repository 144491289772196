import React from "react";
import "./plateforms.css";

export default function Plateforms() {
  return (
    <div className="container plateforms">
      <div className="row">
        <div className="col-md-3 plateforms-card">
          <img src="./images/capterra.jpg" alt="" />
          <p>Capterra 4.7/5</p>
        </div>
        <div className="col-md-3 plateforms-card">
          <img src="./images/app-store.jpg" alt="" />
          <p>Capterra 4.7/5</p>
        </div>
        <div className="col-md-3 plateforms-card">
          <img src="./images/playbtn.jpg" alt="" />
          <p>Capterra 4.7/5</p>
        </div>
      </div>
    </div>
  );
}
