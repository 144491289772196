import React from "react";
import "./service.css";

export default function Service() {
  return (
    <div className="service my-4">
      <div className="container py-5 ">
        <br />
        <div className="row mb-5 ">
          <div className="col text-center">
            <h1 className="fw-bold mb-3 " style={{ fontSize: 36 }}>
              We're Always Here for You
            </h1>
            <h6
              className="fw-normal "
              style={{ fontSize: 18, color: "#f8f8f8" }}
            >
              Revyfy's talented customer support team is available 24/7 to
              assist you.
            </h6>
          </div>
        </div>
        <div className="row gy-5">
          <div className="col-12 mb-3 col-md-4 text-center">
            <h1 className="fw-bold mb-2 " style={{ fontSize: 36 }}>
              24/7
            </h1>
            <h6
              className="fw-normal "
              style={{ fontSize: 18, color: "#f8f8f8" }}
            >
              Live Phone, Chat & Email Support
            </h6>
          </div>
          <div className="col-12 mb-3  col-md-4 text-center">
            <h1 className="fw-bold mb-2 " style={{ fontSize: 36 }}>
              3 min
            </h1>
            <h6
              className="fw-normal "
              style={{ fontSize: 18, color: "#f8f8f8" }}
            >
              Most Calls are Answered Within 3 Minutes
            </h6>
          </div>
          <div className="col-12  mb-3 col-md-4 text-center">
            <h1 className="fw-bold mb-2 " style={{ fontSize: 36 }}>
              95 %
            </h1>
            <h6
              className="fw-normal "
              style={{ fontSize: 18, color: "#f8f8f8" }}
            >
              Customer Satisfaction
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}
