import React from "react";
import "./functionalityFeatures.css";
import FunctionalityFeaturesOption from "./FunctionalityFeaturesOption";

export const FunctionalityFeatures = () => {
  return (
    <div className="container-fluid py-5 functionalityFeatures">
      <div className="container px-md-5 px-sm-2">
        <div className="row px-md-5">
          <div className="col-12 functionalityFeatures-heading">
            <h2>
              Simplicity Meets Functionality. Our Features Make Your Life
              Easier.
            </h2>
          </div>
        </div>
        <div className="row px-md-5 gx-4 gy-4 functionalityFeatures-body">
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Process payments on any device, with rates as low as 1.29% + £0.20, while providing an easy & safe experience." />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Expand your sales with an online store where clients can purchase" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Design a custom booking page for your business with our website builder" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Use an existing email template or customise a marketing campaign to fit your brand or aesthetic" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Free transfer of all your data from any software" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Customisable calendar with waitlist & resource management" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Free listing with unlimited daily deals and bookings on the Revyfy Marketplace" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Automated notifications & appointment reminders" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Online booking on Revyfy, Instagram, Facebook & Apple Maps" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Business & employee booking widget" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Advanced reporting to run your business" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Create, send, & manage invoices" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Online booking on Revyfy, Instagram, Facebook & Apple Maps" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Online booking on Revyfy, Instagram, Facebook & Apple Maps" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Online booking on Revyfy, Instagram, Facebook & Apple Maps" />
          </div>
          <div className="col-md-6">
            <FunctionalityFeaturesOption text="Create, send, & manage invoices" />
          </div>
        </div>
      </div>
    </div>
  );
};
