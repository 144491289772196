import React from "react";
import "./cookies.css";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export default function CookiesComponent() {
  return (
    <div className="cookiesContainer">
      <CookieConsent
        enableDeclineButton={true}
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="myRevyfyCookies"
        style={{
          background: "#e6e6e6",
          color: "var(--light-black)",
          display: "block",
          boxShadow: "0 -1px 5px 5px hsla(0, 0%, 45%, 0.418)",
        }}
        buttonStyle={{
          color: "#ffffff",
          backgroundColor: "var(--light-black)",
          fontSize: "15px",
          marginTop: "0px",
          borderRadius: "3px",
          padding: "5px 15px",
        }}
        declineButtonStyle={{
          color: "var(--light-black)",
          backgroundColor: "#ffffff",
          border: "1px solid var(--light-gray)",
          fontSize: "15px",
          marginTop: "0px",
          borderRadius: "3px",
          padding: "5px 15px",
        }}
        expires={150}
      >
        We use cookies to help our website function. By clicking Accept, we'll
        also use cookies to measure and improve the use, personalize content &
        ads, iteract with social media. If you don't want these cookies, click
        decline. You can also&nbsp;
        <Link>read our privacy policy</Link>
      </CookieConsent>
    </div>
  );
}
