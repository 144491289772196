import React, { useEffect, useState } from "react";
import "./navbar.css";
import LogoDropDown from "../dropdown menus/LogoDropDown/LogoDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Menu from "./Menu";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showDropDown, setShowDropDown] = useState(0);

  useEffect(() => {
    // const navBar = document.getElementById("navBar");

    window.onscroll = function () {
      if (window.scrollY > 22) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    function handleResize() {
      setIsMobile(window.innerWidth <= 990);
    }

    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (n) => {
    setShowDropDown(n);
  };

  const handleMouseLeave = () => {
    handleMouseEnter(0);
  };

  const handleToggleClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg py-1 sticky-top ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <div className="container-fluid">
          <div
            className="brand drop-down"
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave()}
          >
            <span className="navbar-brand">revyfy</span>
            <LogoDropDown isMobile={isMobile} showDropDown={showDropDown} />
          </div>
          <button
            className={`navbar-toggler ${isNavOpen ? "active" : ""}`}
            type="button"
            onClick={handleToggleClick}
          >
            {isNavOpen ? (
              <FontAwesomeIcon className="nav-toggler-icon" icon={faTimes} />
            ) : (
              <FontAwesomeIcon className="nav-toggler-icon" icon={faBars} />
            )}
          </button>

          <Menu isNavOpen={isNavOpen} isMobile={isMobile} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
