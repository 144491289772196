import React from "react";
import "./homeHero.css";

const ProHeader = () => {
  return (
    <div className="container-fluid proHeader">
      <div className="row proHeader-body">
        <div className="col-xm-12 col-md-5 ">
          <div className="row py-3 px-md-5 align-center proHeader-content">
            <div className="col-12">
              <h1 className="proHeader-heading">
                Beauty & wellness bookings with more
              </h1>
            </div>
            <div className="col-12 proHeader-logo-img">
              <img src="./images/va-va-revyfy.svg" alt="" />
            </div>
            <div className="row mt-4 gy-3 header-btns">
              <div className="col-md-6 col-sm-12">
                <button className="btn btn-light trail-btn jump-hover">
                  Start Free Trail
                </button>
              </div>
              <div className="col-md-6 col-sm-12 contact-sales-btn-div jump-hover">
                <button className="contact-sales-btn">
                  <span> Contact Sales </span>
                  <img src="./images/arrow.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-7 proHeader-images">
          <div className="calander-img-div">
            <img
              src="./images/revyfy-home.png"
              className="calander-img"
              alt=""
            />
          </div>
          {/* <img src="./images/mobile.jpg" className="mobile-img" alt="" /> */}
        </div>
      </div>
      <div className="row justify-content-center appointment-container">
        <div className="col-10 appointment-box jump-hover">
          <p>Are you looking to book a service or class? Search on Revyfy.</p>
          <button className="appointment-btn">
            Book an Appointment
            <img src="./images/right-arrow-white.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProHeader;
