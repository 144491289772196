import React from "react";
import "./addOnOption.css";

export default function AddOnOption() {
  return (
    <div className="addOnOption px-md-3 px-sm-1">
      <div className="addOnOption-title">
        <span>Revyfy Drive</span>
        <span>Starting at &pound;10/mo</span>
      </div>
      <p className="addOnOption-desc">
        Securely store photos & files relating to your clients for future
        reference
      </p>
    </div>
  );
}
