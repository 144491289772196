import React from "react";
import BusinessTypesHero from "../../../components/Business Types Hero/BusinessTypesHero";
import LeftToRightFeatureContainer from "../../../components/LeftToRightFeatureContainer/LeftToRightFeatureContainer";
import RightToLeftFeatureContainer from "../../../components/RightToLeftFeatureContainer/RightToLeftFeatureContainer";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";
import FullWidthCard from "../../../components/FullWidthCard/FullWidthCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import ServiceCard from "../../../components/service card/ServiceCard";
import ExploreMore from "../../../components/ExploreMoreSection/ExploreMore";
import Plateforms from "../../../components/PlateForms/Plateforms";
import Customer from "../../../components/Customer/Customer";

export default function Marketplace() {
  const data = [
    {
      heading: "Reminder Notifications",
      desc: "Automated email, text & push notifications to remind or confirm appointments & more.",
      learnMorebtn: "hide",
    },
    {
      heading: "Client Management",
      desc: "Store client info and look up appointments, product purchases, gift certificate package details & more.",
      learnMorebtn: "hide",
    },
    {
      heading: "Forms",
      desc: "Use a template or create your own customized forms to send to clients. After clients are done, forms auto-save to their profile.",
      learnMorebtn: "hide",
    },
    {
      heading: "Email & Text Marketing",
      desc: "Automate email & text marketing to connect with clients at scale.",
      learnMorebtn: "hide",
    },
    {
      heading: "Contactless Check-In",
      desc: "Clients check in from their mobile device, notifying providers of their arrival. A notification is sent to clients when the provider is ready.",
      learnMorebtn: "hide",
    },
    {
      heading: "Reports",
      desc: "View a dashboard to get real-time information at a glance or drill into the details of your business by running customizable reports.",
      learnMorebtn: "hide",
    },
    {
      heading: "Branded Website & App",
      desc: "Strengthen your brand with your personalized Revyfy theme page, or have us build your own branded app.",
      learnMorebtn: "hide",
    },
    {
      heading: "Payroll",
      desc: "Run payroll in minutes, create custom commission tiers, pay employees via direct deposit & more.",
      learnMorebtn: "hide",
    },
    {
      heading: "Rent & Fees",
      desc: "Schedule recurring rent & fees within the software and deduct commission from rent.",
      learnMorebtn: "hide",
    },
  ];
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <BusinessTypesHero
        smallHeading="REVYFY MARKETPLACE"
        heading="Expand Your Reach & Find New Clients"
        desc="35 million appointments a year are booked with businesses like yours through the Revyfy Marketplace."
        img="./images/Fearures Pages imgs/marketplace/hero.webp"
      />

      <HeadingSection
        title="GET FOUND"
        heading="Expand your Clientele"
        details="Automated marketing tools so you can focus on your customers"
      />

      {/* Cards sections */}
      <LeftToRightFeatureContainer
        smallHeading="NO COMMISSION FEES"
        mainHeading="Tap into the Power of the Free Revyfy Marketplace"
        desc="List your business on Revyfy.com and the Revyfy app to attract new customers. Get featured for even more exposure. Promote discounts & special offers in the Daily Deals section–it’s totally free as a Revyfy business!"
        list={[
          "You keep 100% of the profits",
          "Get booked while you sleep",
          "Build trust with verified customer reviews",
        ]}
        imgUrl="./images/Fearures Pages imgs/marketplace/img1.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="DRIVE BOOKINGS WITH FLASH SALES"
        mainHeading="Promote with Daily Deals"
        desc="Promote your specials with our Daily Deals feature and quadruple your chances of being booked! Create a Daily Deal in minutes: Pick an image, add a title & description, then Daily Deals are displayed on your business page, the free Marketplace & optionally through Facebook."
        list={[
          "You keep 100% of the profits",
          "Get booked while you sleep",
          "Build trust with verified customer reviews",
        ]}
        imgUrl="./images/Fearures Pages imgs/marketplace/img2.webp"
      />

      <LeftToRightFeatureContainer
        smallHeading="SHINE A SPOTLIGHT ON YOUR BUSINESS"
        mainHeading="Get Featured & Found"
        desc="When you use the Get Featured option in the free Marketplace, you’re putting your business at the front of the pack. It’s a great way to get your business seen & to rack up the bookings by locals who visit Revyfy.com."
        imgUrl="./images/Fearures Pages imgs/marketplace/img3.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="A PICTURE IS WORTH 1,000 BOOKINGS"
        mainHeading="Business Photo Gallery"
        desc="You do some great work—show it off! Revyfy provides a gallery that acts as your own personal portfolio for any client viewing your business page. Think of it as your chance to highlight why you’re one of the best in your industry. After they see all that you have to offer, they can book right then & there from the very same page."
        imgUrl="./images/Fearures Pages imgs/marketplace/img4.webp"
      />

      {/* customer appointments Section */}
      <HeadingSection
        title="GET FOUND"
        heading="More customer appointments"
        details="Businesses on the Revyfy Marketplace book more new customer appointments per month. *Up to £75,000 in yearly revenue from new customers finding your business on the Revyfy Marketplace.* Based on average of 13 new repeat customers per month at £100 per appointment and a 75% retention rate."
      />

      <div className="container py-5">
        <div className="row gy-4">
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/marketplace/icon1.svg"
              text="35M"
              para="The largest marketplace.35 million appointments booked every year on the Revyfy Marketplace"
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/marketplace/icon2.svg"
              text="20M+"
              para="The largest marketplace.35 million appointments booked every year on the Revyfy Marketplace"
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/marketplace/icon3.svg"
              text="Free"
              para="The largest marketplace.35 million appointments booked every year on the Revyfy Marketplace"
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/marketplace/icon1.svg"
              text="&pound;75,000"
              para="The largest marketplace.35 million appointments booked every year on the Revyfy Marketplace"
            />
          </div>
        </div>
      </div>

      {/* customer appointments Ends */}

      <LeftToRightFeatureContainer
        smallHeading="LET THEM KNOW WHAT YOU HAVE TO OFFER"
        mainHeading="Service & Class Menu"
        desc="Your business page holds a lot of important information, but some of the most necessary is what you offer to your clientele. Whether it’s highlights & extensions, a HIIT class or a Swedish massage, you can list it on your Revyfy page and users can book with you knowing they’ve chosen the right business to get exactly what they’re looking for."
        imgUrl="./images/Fearures Pages imgs/marketplace/img5.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="GIVE CLIENTS OPTIONS WHEN BOOKING"
        mainHeading="Turn a Live Stream into a Revenue Stream"
        desc="In 2021, our customers held 224,261 live sessions for their clients. And now, we’ve zoomed past the competitors with our own Revyfy Live Streaming , which you can access through the same app you use for booking! There are many reasons why live streams may be more convenient for your clients, so offering things like live yoga classes , makeup & hair tutorials or a streamed group workout opens up options for them and avenues of passive income for you."
        imgUrl="./images/Fearures Pages imgs/marketplace/img6.webp"
      />
      <LeftToRightFeatureContainer
        smallHeading="GET GLOWING & GROWING"
        mainHeading="Let Your Reviews Speak for Themselves"
        desc="Word of mouth is a powerful tool for any business, and these days the chatter is all online. So, encourage your clients to leave you feedback for others to see on your Revyfy page! You can even offer promotions like 10% off a next visit for leaving a review. Only verified clientele (those who book with you through Revyfy) can leave reviews, ensuring feedback is fair & honest. You can reply to reviews to help further your client-business relationship and foster customer loyalty."
        imgUrl="./images/Fearures Pages imgs/marketplace/img7.webp"
      />

      {/* Get Paid Section */}
      <div className="get-paid">
        <div className="container">
          <HeadingSection
            title="GET PAID"
            heading="Accept payments safely & securely"
            details="Get paid securely on any device, anywhere."
          />

          <div className="row gy-4 get-paid-payment-features">
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image19.jpg"
                heading="Contactless Payments"
                text="Get paid via Apple Pay, Google Pay & other contactless payment methods
      when customers tap the Revyfy Contactless Credit Card Reader."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image12.jpg"
                heading="Contactless Checkout"
                text="Customers can safely & securely check out and tip using Revyfy's Contactless Checkout."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image10.jpg"
                heading="Card on File"
                text="Store your clients' cards on file for faster checkouts and charge recurring payments."
              />
            </div>
            {/* Revyfy Pay Desk Card  */}
            <div>
              <FullWidthCard
                smallHeading="MASSAGE SOFTWARE & POS SYSTEM"
                mainHeading="Revyfy Pay Desk"
                desc="Our dual-touchscreen point of sale offers your customers a safe & transparent payment experience."
                list={[
                  "Clients can view their checkout in real time",
                  "Tip & sign at checkout",
                  "Market Daily Deals and upload your own images & ads to the customer screen",
                ]}
                learnMoreLink="/"
                imgUrl="./images/salon-imgs/image16.jpg"
              />
            </div>

            {/* Cancelation cards */}
            <div className="row">
              <div className="col-md-6">
                <CardWithImage
                  image="./images/salon-imgs/image14.jpg"
                  heading="No-Show & Cancellation Fees"
                  text="Protect your business from lost revenue by charging no-show & cancellation fees when a client does not give enough notice before their appointment."
                />
              </div>
              <div className="col-md-6">
                <CardWithImage
                  image="./images/salon-imgs/image15.jpg"
                  heading="Deposits"
                  text="Collect deposits at the time of booking salon services. Require deposits for bookings that exceed a specified dollar amount."
                />
              </div>
            </div>

            {/* ONLINE STORE FOR SALON BUSINESSES Card */}
            <div>
              <FullWidthCard
                smallHeading="ONLINE STORE FOR SALON BUSINESSES"
                mainHeading="Sell products, packages, gift cards & more"
                desc="The online store is fully integrated with Revyfy's salon software. The booking & payment processing platform gives you everything you need to market your store, process payments & ship products."
                list={[
                  "Inventory Management",
                  "Customized Shipping Methods",
                  "Curbside Pickup",
                ]}
                learnMoreLink="/"
                imgUrl="./images/salon-imgs/image18.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <ExploreMore data={data} />

      {/* Customer Carousel */}
      <Customer
        smallHeading="REVYFY MARKETPLACE TESTIMONIALS"
        heading="The Best Revyfy Marketplace Software"
      />

      <Plateforms />

      <Trail />

      <Footer />
    </>
  );
}
