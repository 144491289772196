import React, { useState } from "react";
import "./signupForm.css";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import PhoneInput from "react-phone-number-input";

export default function SignupForm() {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [value, setValue] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [selectedPbutton, setSelectedPbutton] = useState("");

  const handleSelectButton = (buttonId) => {
    setSelectedButton(buttonId === selectedButton ? "" : buttonId);
  };

  const handleSelectPbutton = (buttonId) => {
    setSelectedPbutton(buttonId === selectedButton ? "" : buttonId);
  };

  return (
    <div className="container-fluid signupForm">
      <div className="container">
        <div className="row pt-3">
          <div className="col-md-4 signupForm-img">
            <img src="./images/revyfy-screen-login-img.png" alt="" />
          </div>
          <div className="col-md-5 p-0 signupForm-form">
            <div className="signupForm-logo">
              <span>revyfy</span>
            </div>
            <div className="signupButtons px-4">
              <p>
                Sign up with &nbsp;
                <Link className="signupButtons-link">Facebook</Link> or&nbsp;
                <Link className="signupButtons-link">Google</Link> or&nbsp;
                <Link className="signupButtons-link">Apple</Link>
              </p>
            </div>
            <div className="or-divider">
              <div className="line"></div>
              <div className="or-text">or</div>
              <div className="line"></div>
            </div>
            <form>
              <div className="inputGroup-inputs">
                <input
                  type="text"
                  placeholder="First Name"
                  value={fName}
                  onChange={(e) => setfName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lName}
                  onChange={(e) => setlName(e.target.value)}
                />
              </div>
              <div className="inputGroup-phone">
                <PhoneInput
                  className="inputGroup-phone-input"
                  international
                  defaultCountry="US"
                  value={value}
                  onChange={setValue}
                />
              </div>
              <div className="inputGroup">
                <MailOutlineIcon className="inputIcon" />
                <input
                  type="text"
                  placeholder="Username or Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </div>
              <div className="inputGroup">
                <HttpsOutlinedIcon className="inputIcon" />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
              </div>
              <div className="inputGroup-gender">
                <select>
                  <option>Select Gender (optional)</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Non-Binary</option>
                </select>
              </div>

              <div className="inputGroup-appointment">
                <p>Appointment Reminders:</p>
                <div className="inputGroup-appointment-btns">
                  <div
                    id="email"
                    className={`select-button ${
                      selectedButton === "email" ? "selected" : ""
                    }`}
                    onClick={() => handleSelectButton("email")}
                  >
                    Email
                  </div>
                  <div
                    id="text"
                    className={`select-button ${
                      selectedButton === "text" ? "selected" : ""
                    }`}
                    onClick={() => handleSelectButton("text")}
                  >
                    Text
                  </div>
                  <div
                    id="none"
                    className={`select-button ${
                      selectedButton === "none" ? "selected" : ""
                    }`}
                    onClick={() => handleSelectButton("none")}
                  >
                    None
                  </div>
                </div>
              </div>
              <div className="inputGroup-appointment">
                <p>Promotional Emails:</p>
                <div className="inputGroup-appointment-btns">
                  <div
                    id="pEmail"
                    className={`select-button ${
                      selectedPbutton === "pEmail" ? "selected" : ""
                    }`}
                    onClick={() => handleSelectPbutton("pEmail")}
                  >
                    Email
                  </div>

                  <div
                    id="pNone"
                    className={`select-button ${
                      selectedPbutton === "pNone" ? "selected" : ""
                    }`}
                    onClick={() => handleSelectPbutton("pNone")}
                  >
                    None
                  </div>
                </div>
              </div>

              <div className="terms-agr">
                By creating an account, you agree to our
                <Link className="terms-agr-link"> user agreement</Link> and{" "}
                <Link className="terms-agr-link">privacy policy</Link> .
              </div>

              <button type="submit">Create Account</button>
            </form>
            <div className="signup">
              <span>Already have an account?</span>
              <Link to="/login" className="signin-link">
                Signin
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
