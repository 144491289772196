import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function AutoRentFee() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Simplify Rent Collection"
        desc="With all of the tools you need in one software, collecting rent and fees for hair salons, gyms and other businesses has never been easier. Charge booth renters, schedule recurring payments, store renter’s contracts and even deduct commission from booth rent with Revyfy Merchant Services."
        img="./images/Fearures Pages imgs/branded-app/hero.png"
      />
      {/* Cards sections */}
      <LeftImgCard
        heading="Collect Rent Automatically"
        desc="Just set an amount, schedule a charge frequency and let rent and fees flow automatically into your business’s bank account. It’s that simple. No more chasing stylists, trainers and others for checks or cash. No more confusion over due dates. Just timely rental payment."
        img="./images/Fearures Pages imgs/auto-rent/img1.webp"
      />
      <RightImgCard
        heading="Streamline Your Onboarding"
        desc="Onboard your renters through text and email, from which they can easily add payment info and sign their renter’s contracts. With payment information stored automatically to their employee profiles, you no longer have to keep track of routing and credit card numbers for stylists and other new hires."
        img="./images/Fearures Pages imgs/auto-rent/img2.webp"
      />
      <LeftImgCard
        heading="Keep Digital Renter's Agreements"
        desc="Generate legally binding renter’s contracts based on a template developed by our team of lawyers for salon booth rental, gym rental and others using Revyfy Forms. Sign, store and view full-time and part-time rental contracts digitally in your renters' employee profiles for greater accessibility, transparency and trust."
        img="./images/Fearures Pages imgs/auto-rent/img3.webp"
      />
      <RightImgCard
        heading="Two Secure Payment Options"
        desc="Charge booth renters by credit card, or from their bank account. Setting primary and backup rental payment methods means fewer missed or failed payments and greater peace of mind for salon owners, stylists and other renters."
        img="./images/Fearures Pages imgs/auto-rent/img10.webp"
      />
      <LeftImgCard
        heading="Deduct Commission from Rent"
        desc="Automatically deduct a hair stylist's or makeup artist's product sales commission from their booth rent and fees. Less money changing hands for a salon booth rental means less confusion for independent contractors and no need for a 1099 form, which saves you both time during tax season."
        img="./images/Fearures Pages imgs/auto-rent/img4.webp"
      />
      <RightImgCard
        heading="View Rental Payment Info & History"
        desc="Stylists and other booth renters can easily view their payment histories, update their payment information as needed and reference their booth rental agreements from their employee profiles."
        img="./images/Fearures Pages imgs/auto-rent/img5.webp"
      />
      <LeftImgCard
        heading="Taxes are Simple with Automatic 1099s"
        desc="When tax season comes around, skip the manual report generating and entries to file 1099s. The tax forms will be generated automatically with rent amounts and renter information. Just download the 1099s and file."
        img="./images/Fearures Pages imgs/auto-rent/img6.webp"
      />
      <RightImgCard
        heading="Access Clear, Detailed Rental Reports"
        desc="Easily manage booth rental payment and view your renters’ full payment histories at a glance. In the unlikely event that a booth renter's primary and secondary payment methods fail, you can also keep detailed records of how rent was collected (cash, check, PayPal, etc.) for future reference."
        img="./images/Fearures Pages imgs/auto-rent/img7.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
