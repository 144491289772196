import React from "react";
import "./option.css";
import { Link } from "react-router-dom";
export default function Option({ img, text, para, link }) {
  return (
    <>
      <Link to={link} style={{ textDecoration: "none" }}>
        <div className="option-card">
          <div className="option-card-body text-center">
            <div className="option-card-img">
              <img src={img} alt="" />
            </div>
            <h5 className="option-card-title mt-2">{text}</h5>
            <p className="option-card-text">{para}</p>
          </div>
        </div>
      </Link>
    </>
  );
}
