import React from "react";
import "./businessTypesHero.css";
import Time from "../Time/Time";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

export default function BusinessTypesHero({
  smallHeading,
  heading,
  desc,
  img,
  time,
}) {
  return (
    <section className=" businessTypesHero">
      <div className="container py-5">
        <div className="row businessTypesHero-main">
          <div className="col-md-6 pe-lg-5 pe-md-0 businessTypesHero-main-content">
            <h3>{smallHeading}</h3>
            <h1>{heading}</h1>
            <p>{desc}</p>
            <div className="businessTypesHero-main-btns">
              <div className="businessTypesHero-main-trailBtn">
                <Link to="/pricing">
                  <button className="btn btn-dark jump-hover">
                    Start Free Trail
                  </button>
                </Link>
              </div>
              <div className="businessTypesHero-main-contactBtn">
                <Link to="/contact">
                  <button className="btn jump-hover">
                    Start Free Trail
                    <ChevronRightOutlinedIcon className="right-arrow" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-sm-5 businessTypesHero-main-img">
            <img src={img} alt="" />
          </div>
        </div>

        {time !== "hide" ? <Time /> : null}
      </div>
    </section>
  );
}
