import React from "react";
import "./textCardWithBtn.css";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function TextCardWithBtn({
  heading,
  desc,
  cardLink,
  learnMorebtn,
}) {
  return (
    <div className="col-md-3 textCardWithBtn">
      <Link className="textCardWithBtn-link" to={cardLink}>
        <h5>{heading}</h5>
        <p>{desc}</p>

        {learnMorebtn === "hide" ? null : (
          <div className="textCardWithBtn-learnMore">
            <button className="btn jump-hover">
              <span>Learn More</span>
              <ArrowForwardIcon className="textCardWithBtn-learnMore-icon" />
            </button>
          </div>
        )}
      </Link>
    </div>
  );
}
