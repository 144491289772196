import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Inventory() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Inventory"
        desc="Revyfy's inventory is smart and efficient. Track inventory down to the item, view trends and insights with powerful reports. Our purchase orders can automatically reorder products and keep you organized with PO history. Expand your business and sell products online."
        img="./images/Fearures Pages imgs/inventory/hero.png"
      />
      {/* Cards sections */}

      <LeftImgCard
        heading="Manage Inventory"
        desc="Revyfy can import your entire inventory for you, free of charge. You can also add new products, like new yoga blocks , one at a time. Upload images for each product and set a business cost & sales price. Set a low quantity warning for products and even a max quantity level which will automatically reorder products as they get low."
        img="./images/Fearures Pages imgs/inventory/img1.webp"
      />
      <RightImgCard
        heading="Smart Purchase Orders"
        desc="Create and send purchase orders with automatic detection of low quantity products. Email purchase orders to your vendors and print them out for your records. When the order comes in, mark the order as received and the products will automatically be imported into your inventory."
        img="./images/Fearures Pages imgs/inventory/img2.webp"
      />
      <LeftImgCard
        heading="Sell Products Online"
        desc="Sell your products online to increase brand awareness and sales. Revyfy will automatically calculate and charge for shipping costs, or your customers can choose to pick up in store. Print labels for UPS, FedEx, and USPS."
        img="./images/Fearures Pages imgs/inventory/img3.webp"
      />
      <RightImgCard
        heading="Insightful Reports"
        desc="Get an inside look into your top selling products and brands. Track the back bar usage as well as sold, lost, damaged, and expired products."
        img="./images/Fearures Pages imgs/inventory/img2.webp"
      />

      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/inventory/img2.webp"
      />
      <LeftImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/inventory/img2.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
