import React from "react";
import "./pricingSection.css";
import PricingCard from "./PricingCard";

export default function PricingSection() {
  return (
    <div className="container-fluid pricingSection pt-4">
      <div className="container p-md-5">
        <div className="pricingSection-main-heading">
          <h1>Pricing that Fits Every Business</h1>
        </div>
        <div className="pricingCard-section pt-4">
          <PricingCard />
        </div>
      </div>
    </div>
  );
}
