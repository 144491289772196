import React from "react";
import "./leftImgCard.css";

export default function LeftImgCard({ heading, desc, img, smallImg }) {
  return (
    <div className="container py-5 leftImgCard">
      <div className="row">
        <div className="col-12 col-md-6 leftImgCard-img">
          <img src={img} alt="" />
        </div>
        <div className="col-12 col-md-6 leftImgCard-content px-md-5 px-sm-5">
          <p className="leftImgCard-content-heading">{heading}</p>
          <p className="leftImgCard-content-para">{desc}</p>
          {smallImg ? (
            <div className="leftImgCard-content-icon">
              <img src={smallImg} alt="" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
