import React from "react";
import "./brandedApp.css";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";

export default function BrandedApp() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Strengthen your Business with a Branded App"
        desc="Make it easy for clients to interact with your brand anytime, anywhere. Build brand awareness and protect the exclusive relationship with your clients."
        img="./images/Fearures Pages imgs/branded-app/hero.png"
      />
      {/* Cards sections */}
      <LeftImgCard
        heading="Online Booking"
        desc="Clients can book or reschedule appointments or classes from their phone whenever it’s convenient for them."
        img="./images/Fearures Pages imgs/branded-app/img1.webp"
      />

      <RightImgCard
        heading="Online Shopping Cart"
        desc="Give clients a branded app and online store that fits in their pocket! Customers can browse your digital shelves and purchase products, gift cards, memberships and packages right from your app."
        img="./images/Fearures Pages imgs/branded-app/img2.webp"
      />
      <LeftImgCard
        heading="Customer Profile Management"
        desc="Clients can manage every aspect of their customer profile with a simple tap. Keep them connected to your business by letting them review their bookings, membership history, payment information and more at anytime!"
        img="./images/Fearures Pages imgs/branded-app/img3.webp"
      />

      <RightImgCard
        heading="Multi-Location Access"
        desc="Clients can easily view all your available locations and choose the one they want to book with from your branded app."
        img="./images/Fearures Pages imgs/branded-app/img4.webp"
      />
      <LeftImgCard
        heading="Notifications"
        desc="Stay focused on what matters most—your craft & your clientele. We'll take care of sending reminders, confirmations and rescheduling notifications. Clients can opt in or out of notifications on their own, giving them control over their interactions with you."
        img="./images/Fearures Pages imgs/branded-app/img5.webp"
      />

      {/* Branded Setup */}

      <HeadingSection
        heading="Branded Setup is Simple"
        details="From app design to launch, we'll work with you to create and deliver a unique app that fits your brand."
      />
      <div className="container setupCard-container">
        <div className="row">
          <div className="col-md-3 setupCard">
            <CardWithImage
              image="./images/Fearures Pages imgs/branded-app/card1.webp"
              heading="We Design It"
              text="Send us your logo, branding color & additional design elements you'd like included."
            />
          </div>

          <div className="col-md-3 setupCard">
            <CardWithImage
              image="./images/Fearures Pages imgs/branded-app/card2.webp"
              heading="You Approve It"
              text="Your feedback is key to ensuring that we capture the look and feel of your brand. Our designers will work with you until the app looks just the way you envisioned it!"
            />
          </div>

          <div className="col-md-3 setupCard">
            <CardWithImage
              image="./images/Fearures Pages imgs/branded-app/card3.webp"
              heading="We Deliver It"
              text="We'll deliver your app to the Apple App Store and Google Play. You'll get a unique link to share with your clients so they can easily download the app."
            />
          </div>
        </div>
      </div>

      {/* trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
