import React, { useState } from "react";
import "./menu.css";
import { Link } from "react-router-dom";
import BusinessTypesDropDown from "../dropdown menus/BusinessDropDown/BusinessTypesDropDown";
import FeaturesDropDown from "../dropdown menus/FeaturesDropDown/FeaturesDropDown";
import CountryDropDown from "../dropdown menus/CountryDropDown/CountryDropDown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function Menu({ isNavOpen, isMobile }) {
  const [showDropDown, setShowDropDown] = useState(0);
  const [showMobileDropDown, setShowMobileDropDown] = useState(0);

  const handleShowDropDownClick = (dropNo) => {
    if (isMobile) {
      setShowMobileDropDown(dropNo);
    }
  };

  const handleMouseEnter = (n) => {
    if (!isMobile) {
      setShowDropDown(n);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowDropDown(0);
    }
  };

  return (
    <>
      {isMobile && showMobileDropDown !== 0 && isNavOpen ? (
        <div className="back-btn">
          <button onClick={() => setShowMobileDropDown(0)}>
            <ChevronLeftIcon className="back-btn-icon" />
            <span>Back</span>
          </button>
        </div>
      ) : null}
      <div
        className={`${isMobile ? "mobile-menu" : "collapse navbar-collapse"} ${
          isNavOpen ? "show-menu" : ""
        }`}
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            className={`nav-item drop-down`}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleShowDropDownClick(2)}
          >
            <span className="nav-link" aria-current="page">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Business Types
              </span>
              <div className="more-icon">
                <img src="./images/right-arrow.svg" alt="" />
              </div>
            </span>
            {/* <BusinessTypesDropDown
              isMobile={isMobile}
              showDropDown={showDropDown}
              showMobileDropDown={showMobileDropDown}
            /> */}
          </li>
          <li
            className="nav-item drop-down"
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleShowDropDownClick(3)}
          >
            <span className="nav-link" aria-current="page" to="/">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Features
              </span>
              <div className="more-icon">
                <img src="./images/right-arrow.svg" alt="" />
              </div>
            </span>
            {/* <FeaturesDropDown
              isMobile={isMobile}
              showDropDown={showDropDown}
              showMobileDropDown={showMobileDropDown}
            /> */}
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/demo">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Demo
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/pricing">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Pricing
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Contact Us
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Support
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <span className="icon-and-text">
                <img
                  src="./images/dollar.svg"
                  className="mobile-nav-link-icon"
                  alt=""
                />
                Blog
              </span>
            </Link>
          </li>
          <li
            className="nav-item drop-down"
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleShowDropDownClick(4)}
          >
            <span className="nav-link">
              <span className="icon-and-text-country">
                <img
                  className="nav-link-icon"
                  src="images/menu-icons/united-kingdom-flag-icon.png"
                  alt="uk"
                />
                {isMobile ? "United Kingdom" : "UK"}
              </span>
              <div className="more-icon">
                <img src="./images/right-arrow.svg" alt="" />
              </div>
            </span>
            <CountryDropDown
              isMobile={isMobile}
              showDropDown={showDropDown}
              showMobileDropDown={showMobileDropDown}
            />
          </li>
        </ul>
        <div className="align-items-center login-signup-btns">
          <Link className="nav-item login-btn icon-and-text" to="https://revyfy.app">
            <img
              src="./images/dollar.svg"
              className="mobile-nav-link-icon"
              alt=""
            />
            <span className="nav-link">Log In</span>
          </Link>
          <Link className="btn signup-btn" to="/pricing">
            <span>{isMobile ? "Start Free Trail" : "Sign up"}</span>
          </Link>
        </div>
      </div>
    </>
  );
}
