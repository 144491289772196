import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import ServiceCard from "../../../components/service card/ServiceCard";

export default function LiveStream() {
  return (
    <>
      {/* NavBar */}
      <Navbar />

      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Live Stream Your Passion"
        desc="Live stream classes and one-on-one consultations from your computer or your mobile device. Our integration is fully automated and works seamlessly with our booking and payment processing platform."
        img="./images/Fearures Pages imgs/live-stream/hero.png"
      />

      <HeadingSection heading="Keep Business Moving. Move Business Online." />

      {/* Cards sections */}
      <RightImgCard
        heading="Simultaneously Stream All Employees"
        desc="Each employee can conduct their live stream classes or services at any time, even if it overlaps with other employees' live streams. Expand your virtual business by turning each of your employees into live stream stars."
        img="./images/Fearures Pages imgs/live-stream/img1.gif"
      />
      <LeftImgCard
        heading="Customize Your Content"
        desc="Set up one-on-one live stream consultations or boost revenue by teaching a class. Offer live stream only, or hybrid live stream and in-person classes. Limit the number of attendees per class and set a price per virtual session."
        img="./images/Fearures Pages imgs/live-stream/img2.webp"
      />

      {/* Service Section */}

      <div className="container py-5 ">
        <h2
          style={{
            fontSize: "36px",
            color: "var(--light-black)",
            marginBottom: "30px",
            fontWeight: "700",
          }}
        >
          Market Your Virtual Classes & Services
        </h2>
        <div className="row gy-4">
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/live-stream//icon1.svg"
              text="Online Marketplace & App"
              para="Showcase your live stream on Revyfy.com and on the Revyfy Client App."
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/live-stream//icon2.svg"
              text="Social Media"
              para="Use our Facebook, Instagram & Yelp integrations to promote your live stream on social media."
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/live-stream//icon3.svg"
              text="Email Marketing"
              para="Build an email marketing campaign to introduce your live stream to loyal customers."
            />
          </div>
          <div className="col-md-3">
            <ServiceCard
              img="./images/Fearures Pages imgs/live-stream//icon4.svg"
              text="Text Marketing"
              para="Send a quick marketing text message to point clients to your booking page."
            />
          </div>
        </div>
      </div>

      <LeftImgCard
        heading="Elevate the Client Experience"
        desc="Customers can easily book and pay for virtual classes and consultations online. Each booking triggers an automated email, push & text notification with a unique Revyfy meeting link. The same link is also displayed on the appointment page on Revyfy.com and on our Client App. To join the live stream, customers simply click on the link."
        img="./images/Fearures Pages imgs/live-stream/img3.webp"
      />
      <RightImgCard
        heading="Live Stream from Anywhere"
        desc="Live stream straight from the Revyfy platform on your computer. Or connect with your audience on your iPad, iPhone or Android device using the Revyfy Pro App. Customers can join your live stream, for example a yoga class , from any device."
        img="./images/Fearures Pages imgs/live-stream/img4.webp"
      />
      <LeftImgCard
        heading="Get Paid for Live Stream Videos"
        desc="Get paid per class when a customer fully prepays for a live stream service or class. Also get recurring revenue when you create memberships so customers can watch your live stream videos for fee."
        img="./images/Fearures Pages imgs/live-stream/img5.webp"
      />
      <RightImgCard
        heading="Automate Audience Management"
        desc="Revyfy automatically synchronizes the live stream link with your calendar. Need to reschedule? Revyfy immediately updates the live stream meeting and notifies all the participants. Once customers join a live stream, Revyfy changes their status to 'Checked In' and deducts a visit from their membership or package."
        img="./images/Fearures Pages imgs/live-stream/img6.gif"
      />
      <LeftImgCard
        heading="Monitor Your Success"
        desc="Use Revyfy Forms to send out feedback surveys after your live stream to get customer feedback and continuously improve your live stream offering."
        img="./images/Fearures Pages imgs/live-stream/img7.webp"
      />
      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/marketing/img2.webp"
      />
      <LeftImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/live-stream/img3.webp"
      />
      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/marketing/img2.webp"
      />

      {/* trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
