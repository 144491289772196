import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import ServiceCard from "../../../components/service card/ServiceCard";

export default function Invoice() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Revyfy Invoices Get Paid from Anywhere"
        desc="Create and send professional invoices in seconds. Simply email or text your invoice and your clients can pay you anytime, anytime."
        img="./images/Fearures Pages imgs/customer-traking/hero.png"
      />

      {/* Service Section */}

      <div className="container py-5 ">
        <h2
          style={{
            fontSize: "36px",
            color: "var(--light-black)",
            marginBottom: "30px",
            fontWeight: "700",
          }}
        >
          Market Your Virtual Classes & Services
        </h2>
        <div className="row gy-4">
          <div className="col-md-4">
            <ServiceCard
              img="./images/Fearures Pages imgs/invoices/icon1.svg"
              text="Online Marketplace & App"
              para="Showcase your live stream on Revyfy.com and on the Revyfy Client App."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              img="./images/Fearures Pages imgs/invoices/icon2.svg"
              text="Social Media"
              para="Use our Facebook, Instagram & Yelp integrations to promote your live stream on social media."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              img="./images/Fearures Pages imgs/invoices/icon3.svg"
              text="Email Marketing"
              para="Build an email marketing campaign to introduce your live stream to loyal customers."
            />
          </div>
        </div>
      </div>
      {/* Cards sections */}

      <RightImgCard
        heading="Clients Pay Invoices from Anywhere"
        desc="Clients Pay Invoices from Anywhere"
        img="./images/Fearures Pages imgs/invoices/img1.webp"
      />

      <LeftImgCard
        heading="Send Automatic Reminders"
        desc="Reminders are automatically sent for overdue invoices. Send unique links to customers via text or email, and other options."
        img="./images/Fearures Pages imgs/invoices/img2.webp"
      />

      <RightImgCard
        heading="Track and Get Paid"
        desc="Manage your invoices, track payments, and send reminders. Manage all of your invoices from one place."
        img="./images/Fearures Pages imgs/invoices/img3.webp"
      />

      <LeftImgCard
        heading="Integrate with Revyfy Checkout"
        desc="Build a cart in the Revyfy Checkout and send it as an invoice. Get interrupted? Save it for later. Create invoices with services, classes, products, packages, memberships, and gift certificates."
        img="./images/Fearures Pages imgs/invoices/img4.webp"
      />

      <RightImgCard
        heading="Take Invoices to the Next Level with Revyfy Merchant Services"
        desc="Sign up for Revyfy Merchant Services and give your clients the ability to pay invoices online at their convenience with a credit card."
        img="./images/Fearures Pages imgs/invoices/img5.webp"
      />

      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/invoices/img6.webp"
      />
      <LeftImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/invoices/img6.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
