import React from "react";
import "./payments.css";
import { Link } from "react-router-dom";

export default function Payments() {
  return (
    <div className="payments my-5 py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 payments-img">
            <img
              src="./images/image18.jpg"
              alt=""
              width="100%"
              style={{ borderRadius: "20px" }}
            />
          </div>
          <div className="col-md-6 p-4 payments-content">
            <h1 className="fw-bold mb-5" style={{ fontSize: 36 }}>
              Revyfy Payments
            </h1>
            <div className="row mb-3">
              <div className="col">
                <h2 className="text-center fw-bold" style={{ fontSize: 30 }}>
                  1.29%
                </h2>
                <p style={{ fontSize: 16 }} className="text-center">
                  Low rates per chip, tap, and typed-in transaction
                </p>
              </div>
              <div className="col">
                <h2 className="text-center fw-bold" style={{ fontSize: 30 }}>
                  Free
                </h2>
                <p style={{ fontSize: 16 }} className="text-center">
                  Contactless Credit Card Reader
                </p>
              </div>
            </div>
            <div className="payment-benefits" style={{ textAlign: "left" }}>
              <h6 className="mb-3" style={{ fontSize: 18 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#80ed99", marginRight: 10 }}
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                Contactless Payments (Apple Pay)
              </h6>
              <h6 className="mb-3" style={{ fontSize: 18 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#80ed99", marginRight: 10 }}
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                Sell Online & In-House
              </h6>
              <h6 className="mb-3" style={{ fontSize: 18 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#80ed99", marginRight: 10 }}
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                POS Hardware
              </h6>
              <h6 className="mb-3" style={{ fontSize: 18 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#80ed99", marginRight: 10 }}
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                Save Customer Card on File
              </h6>
              <h6 className="mb-3" style={{ fontSize: 18 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "#80ed99", marginRight: 10 }}
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                Recurring Revenue with Memberships
              </h6>
            </div>

            <div
              className="learnMore mt-5"
              style={{ textAlign: "left", fontWeight: "600" }}
            >
              <Link
                to="/"
                style={{ fontSize: 16 }}
                className="ms-3 text-decoration-none"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
