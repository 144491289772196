import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import BusinessTypesHero from "../../../components/Business Types Hero/BusinessTypesHero";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import Accordion from "../../../components/Accordion/Accordion";
import Trail from "../../../components/Trail/Trail";
import Plateforms from "../../../components/PlateForms/Plateforms";
import Customer from "../../../components/Customer/Customer";
import LeftToRightFeatureContainer from "../../../components/LeftToRightFeatureContainer/LeftToRightFeatureContainer";
import RightToLeftFeatureContainer from "../../../components/RightToLeftFeatureContainer/RightToLeftFeatureContainer";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";
import FullWidthCard from "../../../components/FullWidthCard/FullWidthCard";
import ExploreMore from "../../../components/ExploreMoreSection/ExploreMore";

export default function PilatesSoftware() {
  return (
    <>
      <Navbar />
      <BusinessTypesHero
        smallHeading="PILATES SOFTWARE & APP"
        heading="Attract new clients and boost customer loyalty"
        desc="Schedule clients, market your Pilates studio, get booked online, send automatic reminders, manage memberships & process payments on any device."
        img="./images/pilates/hero.webp"
      />

      {/* GET FOUND Heading */}
      <HeadingSection
        title="GET FOUND"
        heading="Attract new salon clients in your local area"
        details="Automated marketing tools so you can focus on your customers."
      />

      {/* FREE REVYFY MARKETPLACE */}
      <LeftToRightFeatureContainer
        smallHeading="FREE REVYFY MARKETPLACE"
        mainHeading="Tap into the power of the free Revyfy Marketplace"
        desc=" List your business on Revyfy.com and the Revyfy app to attract new
              customers. Get featured for even more exposure. Promote discounts
              & special offers in the Daily Deals section–it’s totally free!"
        list={[
          "You keep 100% of the profits",
          "Get booked while you sleep",
          "Build trust with verified customer reviews",
        ]}
        learnMoreLink="/marketplace"
        imgUrl="./images/pilates/market.webp"
      />

      {/*  GET BOOKED Heading*/}
      <HeadingSection
        title="GET BOOKED"
        heading="Keep your calendar full"
        details="Streamline your clients' booking experience with many ways to schedule appointments.  "
      />

      {/* FREE REVYFY MARKETPLACE */}

      <LeftToRightFeatureContainer
        smallHeading="SALON APPOINTMENT SCHEDULING"
        mainHeading="Take control of your calendar"
        desc="Set the lead time required for appointments, giving you time to make yourself available. Set appointment search intervals at 15, 20, 30 minutes, etc. and the order in which service providers are listed. Require clients to provide credit card info for all or specific appointments."
        list={[
          "Automated appointment reminders for your clients & staff",
          "Easily book & manage resources on your calendar",
          "Quickly fill last-minute cancellations using the waitlist",
        ]}
        learnMoreLink="/calendar"
        imgUrl="./images/salon-imgs/image13.jpg"
      />

      {/* ONLINE BOOKING  */}

      <RightToLeftFeatureContainer
        smallHeading="ONLINE BOOKING"
        mainHeading="Let customers book from anywhere"
        desc="Allow your customers to easily book a service from your Revyfy business page, the Revyfy Marketplace, Apple Maps, Instagram, Facebook & Yelp. After they've seen photos of your work & your business, they can book right away without additional steps."
        list={[
          "No-show & cancellation fee capabilities",
          "Integrated with Instagram, Facebook, Apple Maps & Yelp",
          "Embed or link your booking widget anywhere",
        ]}
        learnMoreLink="/online-booking"
        imgUrl="./images/pilates/booking.webp"
      />

      {/* Get Paid Section */}
      <div className="get-paid ">
        <div className="container">
          <HeadingSection
            title="GET PAID"
            heading="Accept payments safely & securely"
            details="Get paid securely on any device, anywhere."
          />
          <div className="row gy-4 get-paid-payment-features">
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image19.jpg"
                heading="Contactless Payments"
                text="Get paid via Apple Pay, Google Pay & other contactless payment methods
          when customers tap the Revyfy Contactless Credit Card Reader."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image12.jpg"
                heading="Contactless Checkout"
                text="Customers can safely & securely check out and tip using Revyfy's Contactless Checkout."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/salon-imgs/image10.jpg"
                heading="Card on File"
                text="Store your clients' cards on file for faster checkouts and charge recurring payments."
              />
            </div>
            {/* Revyfy Pay Desk Card  */}
            <div>
              <FullWidthCard
                smallHeading="MASSAGE SOFTWARE & POS SYSTEM"
                mainHeading="Revyfy Pay Desk"
                desc="Our dual-touchscreen point of sale offers your customers a safe & transparent payment experience."
                list={[
                  "Clients can view their checkout in real time",
                  "Tip & sign at checkout",
                  "Market Daily Deals and upload your own images & ads to the customer screen",
                ]}
                learnMoreLink="/"
                imgUrl="./images/salon-imgs/image16.jpg"
              />
            </div>

            {/* Cancelation cards */}
            <div className="row">
              <div className="col-md-6">
                <CardWithImage
                  image="./images/salon-imgs/image14.jpg"
                  heading="No-Show & Cancellation Fees"
                  text="Protect your business from lost revenue by charging no-show & cancellation fees when a client does not give enough notice before their appointment."
                />
              </div>
              <div className="col-md-6">
                <CardWithImage
                  image="./images/salon-imgs/image15.jpg"
                  heading="Deposits"
                  text="Collect deposits at the time of booking salon services. Require deposits for bookings that exceed a specified dollar amount."
                />
              </div>
            </div>

            {/* ONLINE STORE FOR SALON BUSINESSES Card */}
            <div>
              <FullWidthCard
                smallHeading="ONLINE STORE FOR SALON BUSINESSES"
                mainHeading="Sell products, packages, gift cards & more"
                desc="The online store is fully integrated with Revyfy's salon software. The booking & payment processing platform gives you everything you need to market your store, process payments & ship products."
                list={[
                  "Inventory Management",
                  "Customized Shipping Methods",
                  "Curbside Pickup",
                ]}
                learnMoreLink="/"
                imgUrl="./images/salon-imgs/image18.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      {/* EXPLORE MORE Section */}
      <ExploreMore />

      {/* Customer Carousel */}
      <Customer
        smallHeading="PILATES STUDIO SOFTWARE TESTIMONIALS"
        heading="PILATES STUDIO SOFTWARE TESTIMONIALS"
      />

      {/* Plateforms Section */}
      <Plateforms />

      {/* Accordion */}
      <div
        className="businessTypesAccordion"
        style={{ backgroundColor: "var( --very-light-gray)" }}
      >
        <Accordion
          heading="Pilates Studio Software FAQ"
          data={[
            {
              ques: "What is salon software?",
              ans: "Salon software is a comprehensive business management tool that allows owners to manage their business from a computer, tablet, or phone. It can handle functions such as scheduling, marketing, and payments.",
            },
            {
              ques: "How much should I expect to pay for salon software?",
              ans: "Salon software prices can vary from limited, free plans to monthly rates over $200. Revyfy salon software has a base $25 monthly cost that includes scheduling, membership creation, appointment reminders & many more solutions.",
            },
            {
              ques: "How can I use salon software help me grow my business?",
              ans: "Revyfy nail salon software helps business owners grow their business by:<br/>• Automating many daily operation tasks<br/>• Engage with & market to customers in new ways<br/>• Provide in depth business reports to help owners make informed decisions",
            },
            {
              ques: "Do most salon software include an integrated payment system?",
              ans: "No, most salon software require business owners to find their own payment solution. However, Revyfy offers a payment system that integrates seamlessly with their salon software.",
            },
            {
              ques: "Is customer support typically included with nail salon management software?",
              ans: "Although customer support is typically included with nail salon management software, it's often limited to phone support or a help page. Revyfy offers phone support, online chat, a dedicated support site & a YouTube support channel filled with intuitive videos to help solve your software questions.",
            },
            {
              ques: "How can I integrate nail salon management software into my business operations?",
              ans: "Revyfy offers multiple options to help transition businesses to their nail salon software. These include:<br/>• Importing client lists through the friendly integration team<br/>• Weekly online class training when you need it<br/>• Customer support specialists",
            },
          ]}
        />
      </div>
      <Trail />
      <Footer />
    </>
  );
}
