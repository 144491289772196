import React from "react";
import "./Trail.css";
import { Link } from "react-router-dom";

export default function Trail() {
  return (
    <div className="container trail-container my-5 py-5">
      <div className="row trail-body p-5">
        <div className="col-12 col-md-7 col-sm-12">
          <h2>Try Revyfy for Free</h2>
          <p>
            Create an account in minutes. No contract, cancellation, setup, or
            signup fees. Free data import.
          </p>
          <div className="trail-body-btn">
            <Link to="/">
              <button className="btn btn-dark">Start Free Trail</button>
            </Link>
          </div>
        </div>
        <div className="col-5 col-md-5 col-sm-12 trail-body-img">
          <img src="./images/mobile.jpg" alt="" />
        </div>
      </div>
    </div>
  );
}
