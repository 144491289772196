import React from "react";
import "./accordion.css";
import AccordionItem from "./AccordionItem";

export default function Accordion({ heading, data }) {
  return (
    <div className="container accordion-container p-md-5 p-sm-0">
      <div className="accordion-heading py-4">
        <h2>{heading}</h2>
      </div>
      <div className="px-md-5 px-sm-1 accordion">
        {data?.map((item, index) => {
          return (
            <AccordionItem
              headerText={item.ques}
              contentText={item.ans}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
