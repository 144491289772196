import React from "react";
import "./opportunity.css";

export default function OpportunityCard({ imgUrl, heading, desc }) {
  return (
    <div className="col-sm-12 px-sm-0 px-md-4 col-md-4 col-lg-4 opportunityCard">
      <img src={imgUrl} alt="" width="100%" />
      <h1 className="fw-bold" style={{ fontSize: "36px", textAlign: "left" }}>
        {heading}
      </h1>
      <p
        style={{
          fontSize: "16px",
          textAlign: "left",
          color: "gray",
          paddingRight: "10px",
        }}
      >
        {desc}
      </p>
    </div>
  );
}
