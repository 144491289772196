import React from "react";
import "./contactDemoComponent.css";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function ContactDemoComponent() {
  return (
    <section className="contactDemoComponent">
      <div className="container py-5">
        <form action="" className="py-4 px-5 contactDemoComponent-form">
          <h1 className="contactDemoComponent-heading">Book a Time with Us</h1>
          <h4>
            Revyfy's Sales team is always ready to help out with any questions
            or concerns you may have. Fill the form to request a demo or phone
            consultation.
          </h4>
          <p className="contactDemoComponent-smallheading">
            Which would you like to book?
          </p>
          <div>
            <div className="row px-3 g-5 radio">
              <div className="radio-box">
                <input type="radio" name="radio" id="liveDemo" required />
                <div className="radio-box-label">
                  <TouchAppIcon className="radio-box-label-icon" />
                  <span>A Live Demo</span>
                </div>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  name="radio"
                  id="phoneConsultation"
                  required
                />
                <div className="radio-box-label">
                  <SupportAgentIcon className="radio-box-label-icon" />
                  <span>Phone Consultation</span>
                </div>
              </div>

              <div className="col-md-6 input-box">
                <label htmlFor="fName" className="input-box-label">
                  First Name:
                </label>
                <input type="text" id="fName" required />
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="lName" className="input-box-label">
                  Last Name:
                </label>
                <input type="text" id="lName" required />
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="email" className="input-box-label">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="you@example.com"
                  required
                />
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="phoone" className="input-box-label">
                  Phone:
                </label>
                <input type="text" id="phoone" required />
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="business" className="input-box-label">
                  Business Name:
                </label>
                <input type="text" id="business" required />
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="industry" className="input-box-label">
                  Industry:
                </label>
                <select name="industry" id="industry">
                  <option value="beauty">Beauty</option>
                  <option value="Fintness">Fintness</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-md-4 input-box">
                <label htmlFor="location" className="input-box-label">
                  Location:
                </label>
                <select name="location" id="location">
                  <option value="United States">United States</option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="United Kingdom">United Kingdom</option>
                </select>
              </div>
              <div className="col-md-4 input-box">
                <label htmlFor="noOflocation" className="input-box-label">
                  Number of Location:
                </label>
                <select name="noOflocation" id="noOflocation">
                  <option value="1">1</option>
                  <option value="2-4">2-4</option>
                  <option value="5-10">5-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21-50">21-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101+">101+</option>
                </select>
              </div>
              <div className="col-md-4 input-box">
                <label htmlFor="currSoftware" className="input-box-label">
                  Current Software:
                </label>
                <input type="text" id="currSoftware" placeholder="Optional" />
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="currSoftware" className="input-box-label">
                  Share any initial thoughts that will help prepare for our
                  meeting:
                </label>
                <textarea
                  type="text"
                  id="currSoftware"
                  placeholder="Type message here..."
                />
              </div>

              <div className="send-btn">
                <button type="submit">Send Request Form</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
