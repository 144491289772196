import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Pricing from "./pages/Pricing/Pricing";
import Demo from "./pages/Demo/Demo";
import Contact from "./pages/Contact/Contact";
import Login from "./pages/Login/Login";
//  Business types pages
import SalonSoftware from "./pages/Business Types Pages/Salon-software/SalonSoftware";
import NailSalonSoftware from "./pages/Business Types Pages/Nail-salon-software/NailSalonSoftware";
import BarberSoftware from "./pages/Business Types Pages/Barber-Software/BarberSoftware";
import TattooShopSoftware from "./pages/Business Types Pages/Tattoo-Shop/TattooShopSoftware";
import TrainingSalonSoftware from "./pages/Business Types Pages/Training-salon-software/TrainingSalonSoftware";
import MakeupArtistSoftware from "./pages/Business Types Pages/Makeup-artist/MakeupArtistSoftware";
import HairRemovalStudioSoftware from "./pages/Business Types Pages/Hair-Removal-Studio/HairRemovalStudioSoftware";
import BeautyBroWLashSoftware from "./pages/Business Types Pages/Beauty-Brow-Lash/BeautyBrowLashSoftware";
import SpaSoftware from "./pages/Business Types Pages/Spa-Software/SpaSoftware";
import MassageSoftware from "./pages/Business Types Pages/Massage-Software/MassageSoftware";
import CoachingSoftware from "./pages/Business Types Pages/Coaching-Software/CoachingSoftware";
import AcupunctureSoftware from "./pages/Business Types Pages/Acupuncture-Software/AcupunctureSoftware";
import PhysicalTherapySoftware from "./pages/Business Types Pages/Physical-Therapy/PhysicalTherapy";
import NutritionistSoftware from "./pages/Business Types Pages/Nutritionist-Sotware/NutritunistSoftwware";
import ChiropratorSoftware from "./pages/Business Types Pages/Chiropractor-Software/Chiropractor";
import MedicalSpaSoftware from "./pages/Business Types Pages/Medical-Spa-Software/MedicalSpaSoftware";
import MentalHealthSoftware from "./pages/Business Types Pages/Mental-Health-Software/MentalHealthSoftware";
import PilatesSoftware from "./pages/Business Types Pages/Pilates-Software/PilatesSoftware";
import YogaSoftware from "./pages/Business Types Pages/Yoga-Software/YogaSoftware";
import PersonalTrainingSoftware from "./pages/Business Types Pages/Personal-training-Software/PersonalTrainingSoftware";
import CrossTrainingSoftware from "./pages/Business Types Pages/Cross-training-Software/CrossTrainingSoftware";
import CyclingSoftware from "./pages/Business Types Pages/Cycling-Software/CyclingSoftware";
import DancingStudioSoftware from "./pages/Business Types Pages/Dancing-Studio/DancingStudioSoftware";
import GymSoftware from "./pages/Business Types Pages/Gym-Software/GymSoftware";
import FitnessSoftware from "./pages/Business Types Pages/Fitness-Software/FitnessSoftware";
import Calendar from "./pages/Features Pages/Calendar/Calendar";
import Notifications from "./pages/Features Pages/notifications/Notifications";
import Marketing from "./pages/Features Pages/Marketing/Marketing";
import LiveStream from "./pages/Features Pages/Live-stream/LiveStream";
import Membership from "./pages/Features Pages/memberShip/Membership";
import BrandedApp from "./pages/Features Pages/branded-app/BrandedApp";
import RevyfyApp from "./pages/Features Pages/revyfy-app/RevyfyApp";
import OnlineBooking from "./pages/Features Pages/Online-booking/OnlineBooking";
import CustomerTracking from "./pages/Features Pages/Customer-tracking/CustomerTracking";
import Invoice from "./pages/Features Pages/Invoices/Invoice";
import Payroll from "./pages/Features Pages/payroll/Payroll";
import Reports from "./pages/Features Pages/reports/Reports";
import Website from "./pages/Features Pages/website/Website";
import Forms from "./pages/Features Pages/forms/Forms";
import AutoRentFee from "./pages/Features Pages/Auto-rent Fees/AutoRentFee.";
import Inventory from "./pages/Features Pages/inventory/Inventory";
import PayDesk from "./pages/Features Pages/Pay-desk/PayDesk";
import Marketplace from "./pages/Features Pages/marketplace/Marketplace";
import CardProcessing from "./pages/Features Pages/card-processing/CardProcessing";
import OnlineStore from "./pages/Features Pages/online-store/OnlineStore";
import SignUp from "./pages/Signup/Signup";
import ChatButton from "./components/chat/ChatButton";
import CookiesComponent from "./components/cookies/CookiesComponent";
import ScrollToTop from "./components/ScrollToTop";
import ContactDemo from "./pages/Contact-demo/ContactDemo";
import ContactSales from "./pages/contact-sales/ContactSales";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/support" element={<Navbar />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contact-demo" element={<ContactDemo />} />
        <Route path="/contact-sales" element={<ContactSales />} />

        {/* Business types pages routes*/}
        <Route path="/salon-software" element={<SalonSoftware />} />
        <Route path="/nail-salon-software" element={<NailSalonSoftware />} />
        <Route path="/barber-software" element={<BarberSoftware />} />
        <Route path="/tatoo-shop-software" element={<TattooShopSoftware />} />
        <Route
          path="/training-salon-software"
          element={<TrainingSalonSoftware />}
        />
        <Route
          path="/makeup-artist-software"
          element={<MakeupArtistSoftware />}
        />
        <Route
          path="/hair-removal-studio-software"
          element={<HairRemovalStudioSoftware />}
        />
        <Route
          path="/brow-lash-software"
          element={<BeautyBroWLashSoftware />}
        />
        <Route path="/spa-software" element={<SpaSoftware />} />
        <Route path="/massage-software" element={<MassageSoftware />} />
        <Route path="/coaching-software" element={<CoachingSoftware />} />
        <Route path="/acupuncture-software" element={<AcupunctureSoftware />} />
        <Route
          path="/physical-therapy-software"
          element={<PhysicalTherapySoftware />}
        />
        <Route
          path="/nutritionist-software"
          element={<NutritionistSoftware />}
        />
        <Route
          path="/chiropractor-software"
          element={<ChiropratorSoftware />}
        />
        <Route path="/medical-spa-software" element={<MedicalSpaSoftware />} />
        <Route
          path="/mental-health-software"
          element={<MentalHealthSoftware />}
        />
        <Route path="/pilates-software" element={<PilatesSoftware />} />
        <Route path="/yoga-software" element={<YogaSoftware />} />
        <Route
          path="/personal-training-software"
          element={<PersonalTrainingSoftware />}
        />
        <Route
          path="/cross-training-software"
          element={<CrossTrainingSoftware />}
        />
        <Route path="/cycling-software" element={<CyclingSoftware />} />
        <Route
          path="/dancing-studio-software"
          element={<DancingStudioSoftware />}
        />
        <Route path="/gym-software" element={<GymSoftware />} />
        <Route path="/fitness-software" element={<FitnessSoftware />} />
        {/* Features menu Routes */}
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/live-stream-classes" element={<LiveStream />} />
        <Route path="/memberships" element={<Membership />} />
        <Route path="/branded-app" element={<BrandedApp />} />
        <Route path="/revyfy-app" element={<RevyfyApp />} />
        <Route path="/online-booking" element={<OnlineBooking />} />
        <Route path="/customer-tracking" element={<CustomerTracking />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/invoices" element={<Invoice />} />
        <Route path="/payroll" element={<Payroll />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/website" element={<Website />} />
        <Route path="/card-processing" element={<CardProcessing />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/online-store" element={<OnlineStore />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/automatic-rent-fees" element={<AutoRentFee />} />
        <Route path="/revyfy-pay-desk" element={<PayDesk />} />
      </Routes>

      <ChatButton />
      <CookiesComponent />
    </div>
  );
}

export default App;
