import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Accordion from "../../components/Accordion/Accordion";
import PricingBenefits from "../../components/PricingBenefits/Pricingbenefits";
import AddOnFeatures from "../../components/AddOnFeatures/AddOnFeatures";
import Plateforms from "../../components/PlateForms/Plateforms";
import SecurityInfo from "../../components/SecurityInfo/SecurityInfo";
import { FunctionalityFeatures } from "../../components/FunctionalityFeatures/FunctionalityFeatures";
import PricingSection from "../../components/PricingSection/PricingSection";

export default function Pricing() {
  return (
    <>
      <Navbar />
      <PricingSection />
      <FunctionalityFeatures />
      <Plateforms />
      <SecurityInfo />
      <AddOnFeatures />
      <PricingBenefits />
      <Accordion
        heading="Revyfy Pricing FAQs"
        data={[
          {
            ques: "What is salon software?",
            ans: "Salon software is a comprehensive business management tool that allows owners to manage their business from a computer, tablet, or phone. It can handle functions such as scheduling, marketing, and payments.",
          },
          {
            ques: "How much should I expect to pay for salon software?",
            ans: "Salon software prices can vary from limited, free plans to monthly rates over $200. Revyfy salon software has a base $25 monthly cost that includes scheduling, membership creation, appointment reminders & many more solutions.",
          },
          {
            ques: "How can I use salon software help me grow my business?",
            ans: "Revyfy nail salon software helps business owners grow their business by:<br/>• Automating many daily operation tasks<br/>• Engage with & market to customers in new ways<br/>• Provide in depth business reports to help owners make informed decisions",
          },
          {
            ques: "Do most salon software include an integrated payment system?",
            ans: "No, most salon software require business owners to find their own payment solution. However, Revyfy offers a payment system that integrates seamlessly with their salon software.",
          },
          {
            ques: "Is customer support typically included with nail salon management software?",
            ans: "Although customer support is typically included with nail salon management software, it's often limited to phone support or a help page. Revyfy offers phone support, online chat, a dedicated support site & a YouTube support channel filled with intuitive videos to help solve your software questions.",
          },
          {
            ques: "How can I integrate nail salon management software into my business operations?",
            ans: "Revyfy offers multiple options to help transition businesses to their nail salon software. These include:<br/>• Importing client lists through the friendly integration team<br/>• Weekly online class training when you need it<br/>• Customer support specialists",
          },
        ]}
      />
      <Footer />
    </>
  );
}
