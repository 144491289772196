import React from "react";
import "./cardWithImage.css";
import CheckIcon from "@mui/icons-material/Check";

export default function CardWithImage({ image, heading, text, list }) {
  return (
    <div className="cardWithImage">
      <div className="cardWithImage-img">
        <img src={image} alt="" />
      </div>
      <div className="cardWithImage-content">
        <h3>{heading}</h3>
        <p>{text}</p>
        <div className="cardWithImage-list">
          {list?.map((item, index) => {
            return (
              <div key={index} className="cardWithImage-list-item">
                <CheckIcon className="list-item-icon" />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
