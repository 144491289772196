import React from "react";
import "./opportunity.css";
import OpportunityCard from "./OpportunityCard";
export default function Opportunity() {
  return (
    <div className="opportunity">
      <div className="container-fluid" style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col-12">
            <h1 className="text-center fw-bold " style={{ fontSize: 36 }}>
              Find More Customers and Grow Your Business with Revyfy
            </h1>
          </div>
        </div>
        <br /> <br />
        <div className="row mt-5 mx-5">
          <OpportunityCard
            imgUrl="./images/image14.webp"
            heading="1. Get Found"
            desc="Get discovered by new customers on the Revyfy Marketplace."
          />
          <OpportunityCard
            imgUrl="./images/girl.jpg"
            heading="2. Get Booked"
            desc="Get bookings while you sleep with Revyfy's elegant and seamless
              booking experience.."
          />
          <OpportunityCard
            imgUrl="./images/image17.jpg"
            heading="3. Get Paid"
            desc="Accept payments for bookings, products, recurring memberships, and
              so much more."
          />
        </div>
      </div>
    </div>
  );
}
