import React from "react";
import "./rightImgCard.css";

export default function RightImgCard({ heading, desc, img, smallImg }) {
  return (
    <div className="container py-5 rightImgCard">
      <div className="row">
        <div className="col-12 col-md-6 rightImgCard-content pe-md-5 pe-sm-5">
          <p className="rightImgCard-content-heading">{heading}</p>
          <p className="rightImgCard-content-para">{desc}</p>
          {smallImg ? (
            <div className="rightImgCard-content-icon">
              <img src={smallImg} alt="" />
            </div>
          ) : null}
        </div>
        <div className="col-12 col-md-6 rightImgCard-img">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}
