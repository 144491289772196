import React from "react";
import "./headingSection.css";

export default function HeadingSection({ title, heading, details }) {
  return (
    <section className="headingSection py-5">
      <div className="container">
        <h4 className="headingSection-heading">{title}</h4>
        <h2>{heading}</h2>
        <p>{details}</p>
      </div>
    </section>
  );
}
