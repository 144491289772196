import React from "react";
import "./countryDropDownOption.css";
import { Link } from "react-router-dom";

const CountryDropDownOption = ({ icon, title, pageLink }) => {
  return (
    <Link to={pageLink} className="countryOption">
      <div className="countryOption">
        <div className="countryOption-icon">
          <img src={icon} alt="" />
        </div>
        <div className="countryOption-content">
          <h5>{title}</h5>
        </div>
      </div>
    </Link>
  );
};

export default CountryDropDownOption;
