import React from "react";
import "./time.css";

export default function Time() {
  return (
    <div className="time py-5">
      <div className="container">
        <div className="row mb-2">
          <div className="col py-4">
            <h2 className="text-center fw-bold">
              Time is money, but only when your calendar is full
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 g-0">
            <div className="time__card text-center">
              <img src="./images/vector10.svg" alt="" height="40px" />
              <h2 className="my-3 fw-bold">20 millions</h2>
              <p>clients searching for your business</p>
            </div>
          </div>
          <div className="col-md-4 g-0">
            <div className="time__card text-center">
              <img src="./images/vector11.svg" alt="" height="40px" />
              <h2 className="my-3 fw-bold">8%</h2>
              <p>growth in new client bookings</p>
            </div>
          </div>
          <div className="col-md-4 g-0">
            <div className="time__card text-center">
              <img src="./images/vector12.svg" alt="" height="40px" />
              <h2 className="my-3 fw-bold">174k+</h2>
              <p>professionals on Revyfy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
