import React from "react";
import "./features.css";
import Option from "./Option";

export default function Features() {
  return (
    <>
      <div className="container mb-4 features">
        <div className="row">
          <div className="col">
            <h1 className="text-center features-heading">
              All the Features You Need to Take it to the Next Level
            </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid milestone ">
        <Option
          img="./images/menu-icons/calender.svg"
          text="Calender"
          para="A versatile Scheduler that always stay upto date"
          link="/calendar"
        />
        <Option
          img="./images/menu-icons/online booking.svg"
          text="Online Booking"
          para="A versatile Scheduler that always stay upto date"
          link="/online-booking"
        />
        <Option
          img="./images/menu-icons/payments.svg"
          text="Payments"
          para="A versatile Scheduler that always stay upto date"
          link="/card-processing"
        />
        <Option
          img="./images/menu-icons/notification.svg"
          text="Notifications"
          para="A versatile Scheduler that always stay upto date"
          link="/notifications"
        />
        <Option
          img="./images/menu-icons/customer tracking.svg"
          text="Customer Tracking"
          para="A versatile Scheduler that always stay upto date"
          link="/customer-tracking"
        />
        <Option
          img="./images/menu-icons/forms.svg"
          text="Forms"
          para="A versatile Scheduler that always stay upto date"
          link="/forms"
        />
        <Option
          img="./images/menu-icons/markiting.svg"
          text="Marketing"
          para="A versatile Scheduler that always stay upto date"
          link="/marketing"
        />
        <Option
          img="./images/menu-icons/cart.svg"
          text="Market Place"
          para="A versatile Scheduler that always stay upto date"
          link="/marketplace"
        />
        <Option
          img="./images/menu-icons/store.svg"
          text="Online Store"
          para="A versatile Scheduler that always stay upto date"
          link="/online-store"
        />
        <Option
          img="./images/menu-icons/camera.svg"
          text="Live Stream"
          para="A versatile Scheduler that always stay upto date"
          link="/live-stream-classes"
        />
        <Option
          img="./images/menu-icons/invoice.svg"
          text="Invoices"
          para="A versatile Scheduler that always stay upto date"
          link="/invoices"
        />
        <Option
          img="./images/menu-icons/inventory.svg"
          text="Inventory"
          para="A versatile Scheduler that always stay upto date"
          link="/inventory"
        />
        <Option
          img="./images/menu-icons/membership.svg"
          text="Memberships"
          para="A versatile Scheduler that always stay upto date"
          link="/memberships"
        />
        <Option
          img="./images/menu-icons/payroll.svg"
          text="Payroll"
          para="A versatile Scheduler that always stay upto date"
          link="/payroll"
        />
        <Option
          img="./images/menu-icons/rent.svg"
          text="Rent & Fees"
          para="A versatile Scheduler that always stay upto date"
          link="/automatic-rent-fees"
        />
        <Option
          img="./images/menu-icons/bapp.svg"
          text="Branded App"
          para="A versatile Scheduler that always stay upto date"
          link="/branded-app"
        />
        <Option
          img="./images/menu-icons/reports.svg"
          text="Reports"
          para="A versatile Scheduler that always stay upto date"
          link="/reports"
        />
        <Option
          img="./images/menu-icons/paydesk.svg"
          text="Pay Desk (POS)"
          para="A versatile Scheduler that always stay upto date"
          link="/revyfy-pay-desk"
        />
        <Option
          img="./images/menu-icons/mobile.svg"
          text="Mobile Apps"
          para="A versatile Scheduler that always stay upto date"
          link="/revyfy-app"
        />
        <Option
          img="./images/menu-icons/web builder.svg"
          text="Website Builder"
          para="A versatile Scheduler that always stay upto date"
          link="/website"
        />
      </div>
      <div className="row features-scroll">
        <div className="col-12 features-scroll-icon">
          <span>Scroll</span>
          <img src="./images/scroll-arrow.svg" alt="" />
        </div>
      </div>
    </>
  );
}
