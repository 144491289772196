import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import BusinessTypesHero from "../../../components/Business Types Hero/BusinessTypesHero";
import LeftToRightFeatureContainer from "../../../components/LeftToRightFeatureContainer/LeftToRightFeatureContainer";
import RightToLeftFeatureContainer from "../../../components/RightToLeftFeatureContainer/RightToLeftFeatureContainer";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";
import FullWidthCard from "../../../components/FullWidthCard/FullWidthCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import Accordion from "../../../components/Accordion/Accordion";
import Trail from "../../../components/Trail/Trail";

export default function CardProcessing() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <BusinessTypesHero
        smallHeading="CREDIT CARD PROCESSING"
        heading="Convenient, Fast, & Secure Payments with Revyfy"
        desc="Process payments on any device while providing an easy & safe experience online or in-store."
        img="./images/Fearures Pages imgs/card-processing/hero.png"
        time="hide"
      />

      <RightToLeftFeatureContainer
        smallHeading="CREDIT CARD ON FILE"
        mainHeading="Recurring Payments"
        list={[
          "Store credit card info when booking online or in-house",
          "Set up recurring card charges on memberships & packages",
          "Automatically update credit cards that are about to expire",
        ]}
        imgUrl="./images/Fearures Pages imgs/card-processing/img1.webp"
      />

      {/* Get Paid Section */}
      <div className="get-paid">
        <div className="container">
          <HeadingSection heading="Protect Your Time & Money" />

          <div className="row gy-4 get-paid-payment-features">
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/card-processing/card1.webp"
                heading="Require Deposits at Booking"
                list={[
                  "Set the deposit amount for any service or class. Protect your time from last-minute cancellations and no shows.",
                  "Protect your time from last-minute cancellations and no shows.",
                ]}
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/card-processing/card2.webp"
                heading="Charge No-Show & Cancellation Fees"
                list={[
                  "Charge no-show and cancellation fees when a client does not give enough notice before their appointment.",
                ]}
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/card-processing/card3.webp"
                heading="Check Out Clients on Their Phone"
                list={[
                  "Provide a safe & contactless payment experience.",
                  "Send the checkout screen directly to customers' phones to add tip & sign.",
                ]}
              />
            </div>

            {/* ONLINE STORE Card  */}
            <div>
              <FullWidthCard
                smallHeading="ONLINE STORE"
                mainHeading="An Easier Way to Take Payments Online"
                list={[
                  "Take your business online & never miss a sale using your online store",
                  "Pre-pay for services & sell products, gift cards, memberships, & packages 24/7",
                  "Capture credit cards for online purchases safely & securely",
                ]}
                learnMoreLink="/online-store"
                imgUrl="./images/Fearures Pages imgs/card-processing/img2.webp"
              />
            </div>
          </div>
        </div>
      </div>

      <RightToLeftFeatureContainer
        smallHeading="POINT-OF-SALE HARDWARE"
        mainHeading="POS Hardware for Every Part of Your Business"
        list={[
          "Free Credit Card Reader with your merchant account",
          "Pre-pay for services & sell products, gift cards, memberships, & packages 24/7",
          "Capture credit cards for online purchases safely & securely",
        ]}
        learnMoreLink="/revyfy-pay-desk"
        imgUrl="./images/Fearures Pages imgs/card-processing/img3.webp"
      />

      <LeftToRightFeatureContainer
        smallHeading="SPLIT PAYMENTS"
        mainHeading="Multiple Payments Processed in One Transaction"
        list={[
          "Automatically deposit money into multiple bank accounts for businesses with independent service providers",
          "Allow customers to see several employees in one visit and pay each individually with only one transaction",
        ]}
        imgUrl="./images/Fearures Pages imgs/card-processing/img4.webp"
      />

      <RightToLeftFeatureContainer
        smallHeading="RENT & FEES"
        mainHeading="Simplify Rent Collection"
        desc="Charge booth renters, schedule recurring payments, store renters' contracts and even deduct commission from booth rent with Revyfy Merchant Services."
        list={[
          "Collect rent automatically",
          "Streamline your onboarding",
          "Keep digital renters' agreements",
        ]}
        learnMoreLink="/automatic-rent-fees"
        imgUrl="./images/Fearures Pages imgs/card-processing/img4.webp"
      />

      {/* Card Processing FAQs*/}
      <div
        className="businessTypesAccordion"
        style={{ backgroundColor: "var( --very-light-gray)" }}
      >
        <Accordion
          heading="Card Processing FAQ"
          data={[
            {
              ques: "What are Revyfy's rate and fees?",
              ans: "2.2% to 2.75% based on the size of your business. Revyfy offers payment processing to businesses in the United States, Australia, Canada &amp; the United Kingdom.",
            },
            {
              ques: "When will I get my money?",
              ans: "Yes. Set a threshold for any service or class you want to require a deposit on and the client can pay online at the time of booking.",
            },
            {
              ques: "Can I charge for no-shows and cancellations?",
              ans: "Yes. By turning on the Capture Credit Card feature in the Online Shopping Cart, you can save credit cards on file and charge a no-show or cancellation fee through the desktop or Revyfy Pro App.",
            },
            {
              ques: "Can I sell my retail and/or gift cards online?",
              ans: "Yes. Through the Online Shopping Cart, you can allow customers to purchase products & gift cards that you offer.",
            },
          ]}
        />
      </div>

      <Trail />
      <Footer />
    </>
  );
}
