import React from "react";
import "./companyInfo.css";
import { Link } from "react-router-dom";

export default function CompanyInfo() {
  return (
    <>
      <div className="container divider"></div>
      <div className="container py-5 companyInfo">
        <div className="row">
          <div className="col-md-4 pb-3 companyInfo-heading">
            <span> Company Info</span>
          </div>
          <div className="col-md-8 companyInfo-details">
            <div className="row gy-4">
              <div className="col-md-6 detail">
                <h3>United Kingdom HQ</h3>
                <p>
                  20-22, Wenlock Road, London, England, N1 7GU
                </p>
              </div>
              <div className="col-md-6 detail">
                <h3>Revyfy Support</h3>
                <p>
                  Revyfy's Customer Support team is always ready to help out
                  with any questions or concerns you may have. Contact us by
                  phone, email or instant chat.
                </p>
              </div>
            </div>
            {/* <div className="row gy-4">
              <div className="col-md-6 detail">
                <h3>United Kingdom HQ</h3>
                <p>25 Town Square City and Zip Stevenage, SG1 1BP, UK</p>
              </div>
              <div className="col-md-6 detail">
                <Link className="contact-link">
                  <p>020 3876 9856 ext. 2</p>
                </Link>
                <Link className="contact-link">
                  <p>support@revyfy.com</p>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container divider"></div>
    </>
  );
}
