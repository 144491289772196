import React from "react";
import "./pricingBenefits.css";
import PricingBenefitCard from "./PricingBenefitCard";

export default function PricingBenefits() {
  return (
    <div className="container-fluid p-5 pricingBenefits">
      <div className="container">
        <div className="row">
          <div className="col-12 pb-5 pricingBenefits-heading">
            <h2>The Bottom Line on our Pricing</h2>
          </div>
          <div className="row pricingBenefits-body">
            <div className="col-md-4">
              <PricingBenefitCard
                title="All profits are yours"
                desc="We take £0 from your online booking & all revenue you make from the Revyfy Marketplace is yours."
              />
            </div>
            <div className="col-md-4">
              <PricingBenefitCard
                title="No long-term contracts"
                desc="No contract fees, cancellation fees, setup fees, signup fees, or PCI-compliance fees. We think they're a bad look."
              />
            </div>
            <div className="col-md-4">
              <PricingBenefitCard
                title="Only pay for what you need"
                desc="Pick and choose the add ons that power your business, and don't pay for the features you don't need."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
