import React from "react";
import "./businessCard.css";
import { Link } from "react-router-dom";

export default function BusinessCard({
  type,
  img,
  dropDown,
  isActive,
  onClick,
}) {
  return (
    <>
      <div className="card businessCard" onClick={onClick}>
        <img src={img} className="card-img" alt="..." />
        <div className="card-img-overlay d-flex justify-content-center align-items-center">
          <h1 className="card-title text-white">{type}</h1>
        </div>
      </div>
      {isActive && (
        <div className="businessCard-dropDown ">
          {dropDown?.map((item, index) => {
            return (
              <div key={index} className="businessCard-dropDown-option">
                <Link
                  to={item.link}
                  className="businessCard-dropDown-option-link"
                >
                  <div className="businessCard-dropDown-option-option">
                    <img src={item.icon} alt="" />
                    <span>{item.title}</span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
