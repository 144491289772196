import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function CustomerTracking() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Customer Tracking"
        desc="Revyfy's customer tracking is designed to help you keep track of detailed information on your clients. Information like customer preferences, allergy details, etc. can assist you in giving a personal experience for your clients."
        img="./images/Fearures Pages imgs/customer-traking/hero.png"
      />
      {/* Cards sections */}

      <LeftImgCard
        heading="Upload Files to Customer Profiles"
        desc="Upload images, videos, PDFs, digital waivers & more to your customers profile directly from the calendar screen. You can even link customer files to a specific appointment so the service provider can quickly access them during an appointment."
        img="./images/Fearures Pages imgs/customer-traking/img1.gif"
      />

      <RightImgCard
        heading="Manage Your Files with the Revyfy Drive Dashboard"
        desc="Keep customer and business files in one place. It's easier than ever to organize and manage your files in folders with the Revyfy Drive Dashboard. Add before-and-after photos, digital forms, and more to your customer's profile, accessible to you from anywhere."
        img="./images/Fearures Pages imgs/customer-traking/img2.webp"
      />
      <LeftImgCard
        heading="Customer Information"
        desc="View all the details about each of your customers from any device. Look up anything from contact information, previous services or products purchased, notes, gift certificates, and much more. Always have the information you need at your fingertips."
        img="./images/Fearures Pages imgs/customer-traking/img3.webp"
      />

      <RightImgCard
        heading="Notes"
        desc="Make notes about your customers including formulas, allergies, preferences, and details like their birthday. Our popup notes feature displays urgent notes on their appointment. All notes are accessible from a computer, phone or tablet."
        img="./images/Fearures Pages imgs/customer-traking/img4.webp"
      />
      <LeftImgCard
        heading="SOAP Notes"
        desc="Each SOAP note is automatically tied to a customer profile. Images can be uploaded to your SOAP notes for reference to your patient's progress. You can create a new SOAP note or edit an existing one. SOAP notes can be referenced at any point and can be updated by employees."
        img="./images/Fearures Pages imgs/customer-traking/img5.webp"
      />

      <RightImgCard
        heading="Forms"
        desc="Create custom forms or use a template to quickly get started. Build intake forms, liability waivers, customer surveys, and more. Send forms out via Revyfy's email marketing, embed it in your website, share the link, or require the form to be filled when booking an appointment. Completed forms will automatically save to your customers' profiles."
        img="./images/Fearures Pages imgs/customer-traking/img6.webp"
      />

      <LeftImgCard
        heading="HIPAA Compliant"
        desc="Revyfy follows the Health Information Portability & Accountability Act's (HIPAA) Privacy Rule, Security Rule and Omnibus Rule. If your business handles electronically protected health information (e-PHI), you must implement security protocols and privacy policies in your business practices which adhere to HIPAA's compliance guidelines. Revyfy is proud to provide a fully HIPAA-compliant software system to your business & clients."
        img="./images/Fearures Pages imgs/customer-traking/img7.webp"
      />
      <RightImgCard
        heading="Memberships"
        desc="Create different membership levels for your business such as Platinum, Gold & Silver. Use our automatic billing to charge for memberships and give different perks or discounts for each level."
        img="./images/Fearures Pages imgs/customer-traking/img8.webp"
      />
      <LeftImgCard
        heading="Packages"
        desc="Entice your customer to prepay for services by allowing them to buy multiple visits ahead of time at a special discounted price. Revyfy automatically tracks remaining visits and the customer can view their balance online or in the app."
        img="./images/Fearures Pages imgs/customer-traking/img9.webp"
      />
      <RightImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/customer-traking/img6.webp"
      />
      <LeftImgCard
        heading=""
        desc=""
        img="./images/Fearures Pages imgs/customer-traking/img6.webp"
      />

      <Trail />

      <Footer />
    </>
  );
}
