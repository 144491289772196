import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Trail from "../../components/Trail/Trail";
import Footer from "../../components/Footer/Footer";
import DemoComponent from "../../components/Demo-component/DemoComponent";
import CompanyInfo from "../../components/Company Info/CompanyInfo";

export default function ContactDemo() {
  return (
    <>
      <Navbar />
      <DemoComponent />
      <CompanyInfo />
      <Trail />
      <Footer />
    </>
  );
}
