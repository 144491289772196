import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Calendar() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Take control of your calendar"
        desc="Our booking system has a full suite of customisable scheduling tools to fit your needs. Automate your salon, spa, or fitness business and get vital appointment details at a glance."
        img="./images/Fearures Pages imgs/calendar/hero.jpg"
      />

      {/* Cards */}

      <LeftImgCard
        heading="Easily Manage Your Business Calendar from Anywhere"
        desc="With one click, view the schedule of every employee, any day. Sync your Revyfy schedule with your Apple, Google or Outlook calendars. All of your business calendar features are accessible via web & mobile, making running your business convenient, no matter where you are."
        img="./images/Fearures Pages imgs/calendar/img1.webp"
      />

      <RightImgCard
        heading="Customise Your Business Calendar with Pinch to Zoom & More"
        desc="Busy schedule? Easily view all the appointment and class details on your phone, no problem, with the vertical pinch & zoom function to get a closer look at each booking. Care to customise? Change the way your services & classes look on your calendar by customising services via different color blocks, making it easier to identify & read at a glance."
        img="./images/Fearures Pages imgs/calendar/img2.gif"
      />

      <LeftImgCard
        heading="Change Bookings With a Simple Drag & Drop"
        desc="Drag & drop appointments anywhere on your business calendar. This simple function makes easy work of implementing any changes to appointments. Reschedule an appointment or class quickly from any device and send notifications about changes to your customers & employees, all from one place."
        img="./images/Fearures Pages imgs/calendar/img3.gif"
      />

      <RightImgCard
        heading="Quickly Access All Your Customer Information"
        desc="Easily access, input and edit customer information from both desktop & the app for a faster booking experience. From viewing all customer notes to saving credit cards on file, Revyfy will keep all your customers' information in one place. You can take deposits, charge no-show fees or easily view a customer's appointment history directly from the calendar."
        img="./images/Fearures Pages imgs/calendar/img4.webp"
      />

      <LeftImgCard
        heading="Schedule Your Appointments an Easier Way"
        desc="Rebooking appointments is made easy with the Jump By Week button to search available appointments weeks in advance. You can automate recurring appointments & classes and never worry about rescheduling regular clients again. Just set how often you want appointments or classes to repeat and it will book automatically. Quickly book on your computer or via the app."
        img="./images/Fearures Pages imgs/calendar/img5.webp"
      />

      <RightImgCard
        heading="Add Customers to the Waitlist"
        desc="Customers can be added to the waitlist when your schedule is fully booked. Manage your customer waitlist or let us choose for you. From first in line to the manual your pick option, you decide who the lucky one is."
        img="./images/Fearures Pages imgs/calendar/img6.webp"
      />

      <LeftImgCard
        heading="Block Off Personal Time"
        desc="Taking time for yourself? Good idea. Simply enter in the dates you'll be out and our booking software will block that time off from online booking. While you're away, clients can continue to schedule for when you return."
        img="./images/Fearures Pages imgs/calendar/img7.webp"
      />

      <RightImgCard
        heading="Resource Scheduling"
        desc="Do you have appointments that require a room or specific piece of equipment to perform the service, like a massage room or tanning bed? When booking an appointment or class, you have the option to reserve a resource or room to ensure it's available to you come appointment time."
        img="./images/Fearures Pages imgs/calendar/img8.webp"
      />

      <LeftImgCard
        heading="Automatically Notify Customers of Changes"
        desc="Save time by automatically sending clients and service providers an email, text or push notification whenever an appointment is booked or changed."
        img="./images/Fearures Pages imgs/calendar/img9.webp"
      />

      <RightImgCard
        heading="Smarter Bookings to Save You Time"
        desc="Maximize your schedule with double-booking capabilities. If you can handle it, we can book it. Specify your gap start time & duration and we'll fill your schedule with our intelligent online booking algorithms. Other booking options available are Cleanup Time to give you necessary padding between visits, Class Categories for a simplified menu, and Multi-Location Management for an easier way to schedule & share information across several locations."
        img="./images/Fearures Pages imgs/calendar/img10.webp"
      />
      <LeftImgCard
        heading="Customize Your Business Calendar Settings"
        desc="Configure the starting day of the business calendar. Select the order in which you want to see the service providers. It's your world, we just live in it."
        img="./images/Fearures Pages imgs/calendar/img11.webp"
      />

      <RightImgCard
        heading="Easily Jump By Week"
        desc="Are you tired of figuring out what date 3 weeks from today will be so you can book that next appointment? Use our quick-jump buttons to set appointments at specific intervals. Work smarter, not harder, right?"
        img="./images/Fearures Pages imgs/calendar/img12.webp"
      />
      <LeftImgCard
        heading="Keep Working Hours Flexible"
        desc="Set your working hours and we'll take care of the rest. Working on a day off? Just open up your hours for that particular date and online booking will be available for that day only."
        img="./images/Fearures Pages imgs/calendar/img13.webp"
      />

      <RightImgCard
        heading="Quickly Identify Appointment Status"
        desc="Each appointment is color-coded by status. Set a status for each booking by simply tapping on it and selecting one of the status options from the drop-down menu: Accept, Deny, Confirm, Show, No-Show, Ready to Start Service, Service in Progress, Cancel or Delete."
        img="./images/Fearures Pages imgs/calendar/img14.webp"
      />
      <LeftImgCard
        heading="Customise Your Classes"
        desc=""
        img="./images/Fearures Pages imgs/calendar/img15.webp"
      />

      <RightImgCard
        heading="Sync & Export Your Calendar"
        desc="Want to see your business appointments next to your personal schedule? Sync your Revyfy Calendar with Google, Apple or Outlook calendars"
        img="./images/Fearures Pages imgs/calendar/img16.webp"
      />

      {/* trail Component */}
      <Trail />

      {/* Footer */}
      <Footer />
    </>
  );
}
