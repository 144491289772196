import React from "react";
import "./notifications.css";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Notifications() {
  return (
    <>
      {/* NavBar */}
      <Navbar />

      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Notifications"
        desc="Revyfy's Notifications will make your life easier. Business owners can focus on what matters most—their craft. And with notifications, no-shows are reduced and clients are happier."
        img="./images/Fearures Pages imgs/notifications/hero.png"
      />

      {/* Cards sections */}

      <LeftImgCard
        heading="Email"
        desc="Automated email reminders are sent to your clients for their upcoming appointments. Setup post-appointment Thank-You emails. Clients can click on the View Your Appointments button to see the appointment status online."
        img="./images/Fearures Pages imgs/notifications/img1.webp"
      />

      <RightImgCard
        heading="Text"
        desc="Text communication is fast & efficient. Your clients can receive reminders, confirmations and anything else relating to their appointments. Clients can opt in or out of text messages on their own keeping you compliant."
        img="./images/Fearures Pages imgs/notifications/img2.webp"
      />
      <LeftImgCard
        heading="Push Notifications"
        desc="Once your clients install the Revyfy client app, they will instantly be notified about anything relating to their appointments and even your last-minute promotions. Clients will be able to add the appointments to their phone calendar in seconds."
        img="./images/Fearures Pages imgs/notifications/img3.webp"
      />

      <RightImgCard
        heading="In-App Notifications"
        desc="The Revyfy Pro App allows you to view your notifications including requested, confirmed, and cancelled appointments. With one click you can call or email the customer related to each notification."
        img="./images/Fearures Pages imgs/notifications/img4.webp"
      />

      <Trail />
      <Footer />
    </>
  );
}
