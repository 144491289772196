import React from "react";
import "./countryDropDown.css";
import CountryDropDownOption from "./CountryDropDownOption";

const CountryDropDown = ({ isMobile, showDropDown, showMobileDropDown }) => {
  return (
    <div
      className={`drop-down-menu country-menu-card ${
        showDropDown === 4 ? "drop-down-menu-show" : ""
      } ${isMobile ? "mobile-dropDown" : ""} ${
        showMobileDropDown === 4 ? " mobile-dropDown-show" : ""
      }`}
    >
      <img src="./images/menu-icons/triangle.svg" className="tip" alt="" />
      <div className="row drop-down-options p-3 country-options">
        <div className="p-0">
          <CountryDropDownOption
            icon="./images/menu-icons/australia-flag-icon.png"
            title="Australia"
            pageLink="/"
          />
          <CountryDropDownOption
            icon="./images/menu-icons/canada-flag-icon.png"
            title="Canada"
            pageLink="/"
          />
          <CountryDropDownOption
            icon="./images/menu-icons/united-states-flag-icon.png"
            title="United States"
            pageLink="/"
          />
        </div>
      </div>
    </div>
  );
};

export default CountryDropDown;
