import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Marketing() {
  return (
    <>
      {/* NavBar */}
      <Navbar />

      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Marketing to Grow Your Business"
        desc="Promote your services on our App and on Revyfy.com, our online Marketplace. Use Revyfy Email and Text Marketing to connect with clients at scale. Showcase your business on a branded website and app."
        img="./images/Fearures Pages imgs/marketing/hero.png"
      />

      {/* Cards sections */}

      <LeftImgCard
        heading="Get Booked with Revyfy"
        desc="Millions of users are searching for salons, spas & fitness businesses on Revyfy. Upload photos to showcase your work & portfolio. Build your business with reviews from customers with before-and-after photos of your clients. Set yourself apart from the rest and book clients on the spot!"
        img="./images/Fearures Pages imgs/marketing/img1.webp"
      />

      <RightImgCard
        heading="Respond to Customer Reviews"
        desc="Engage with clients by publicly responding to reviews from verified customers. A well-crafted response improves a customer's experience and shows that you care about your clients. This leaves you with a better overall rating and establishes trust with a potential client in the future."
        img="./images/Fearures Pages imgs/marketing/img2.webp"
      />
      <LeftImgCard
        heading="Daily Deals"
        desc="Promote your specials, sales & discounts with our Daily Deals to bring in more business. By adding a Daily Deal, you are 4x more likely to be booked by new customers! Simply select one of our hundreds of photos, add a title & description, and customers can book your services, like yoga classes , immediately."
        img="./images/Fearures Pages imgs/marketing/img3.webp"
      />

      <RightImgCard
        heading="Automated Email Marketing"
        desc="Take advantage of our email marketing tools to send emails to your customers & employees. Wish them a happy birthday, invite them to connect on social media, or remind them when they’re due for another visit."
        img="./images/Fearures Pages imgs/marketing/img4.webp"
      />
      <LeftImgCard
        heading="Automated Text Marketing"
        desc="Send a text to a specific customer or set up text campaigns to automatically send on birthdays, before or after appointments, or to lost customers. Text message marketing has proven higher open rates than email or social media marketing."
        img="./images/Fearures Pages imgs/marketing/img5.webp"
      />

      <RightImgCard
        heading="Website Builder"
        desc="Use our website builder to craft a responsive page that mirrors the style & brand of your salon, spa or fitness business. Your customers can book with you 24/7, view your products, services, business details, and even look up their own profile for appointment history, upcoming services & purchases made."
        img="./images/Fearures Pages imgs/marketing/img6.webp"
      />

      <Trail />
      <Footer />
    </>
  );
}
