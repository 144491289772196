import React from "react";
import "./pricingBenefitCard.css";

export default function PricingBenefitCard({ title, desc }) {
  return (
    <div className="pricingBenefitCard px-4">
      <div className="pricingBenefitCard-icon">
        <img src="./images/green-tick.svg" alt="" />
      </div>
      <h3>{title}</h3>
      <p>{desc}</p>
    </div>
  );
}
