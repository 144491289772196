import React, { useState } from "react";
import "./business.css";
import BusinessCard from "./BusinessCard";

export default function Business() {
  const [activeDropDownIndex, setActiveDropDownIndex] = useState(null);

  const handleCardClick = (index) => {
    setActiveDropDownIndex(index === activeDropDownIndex ? null : index);
  };

  return (
    <div className="business py-5 mt-3 mb-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col">
            <h2 className="text-center fw-bold mb-4">
              Run Your Business with Revyfy
            </h2>
            <h6
              className="text-center text-secondary fw-normal"
              style={{ fontSize: 17 }}
            >
              Revyfy is a full business solution for a wide range of business
              types.
            </h6>
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-12 col-md-4">
            <BusinessCard
              type="Beauty"
              img="./images/Beauty-sales.webp"
              dropDown={[
                {
                  icon: "./images/menu-icons/scessior.svg",
                  title: "Salon",
                  link: "/salon-software",
                },
                {
                  icon: "./images/menu-icons/nail.svg",
                  title: "Nail",
                  link: "/nail-salon-software",
                },
                {
                  icon: "./images/menu-icons/barber.svg",
                  title: "Barber",
                  link: "/barber-software",
                },
                {
                  icon: "./images/menu-icons/tattoo.svg",
                  title: "Tattoo",
                  link: "/tatoo-shop-software",
                },
                {
                  icon: "./images/menu-icons/training.svg",
                  title: "Training",
                  link: "/training-salon-software",
                },
                {
                  icon: "./images/menu-icons/makeup.svg",
                  title: "Makeup",
                  link: "/makeup-artist-software",
                },
                {
                  icon: "./images/menu-icons/hair.svg",
                  title: "Hair Removal",
                  link: "/hair-removal-studio-software",
                },
                {
                  icon: "./images/menu-icons/beauty.svg",
                  title: "Beauty",
                  link: "/brow-lash-software",
                },
              ]}
              isActive={activeDropDownIndex === 0}
              onClick={() => handleCardClick(0)}
            />
          </div>
          <div className="col-12 col-md-4">
            <BusinessCard
              type="Wellness"
              img="./images/Wellness-sales.webp"
              dropDown={[
                {
                  icon: "./images/menu-icons/spa.svg",
                  title: "Spa",
                  link: "/spa-software",
                },
                {
                  icon: "./images/menu-icons/massage.svg",
                  title: "Massage",
                  link: "/massage-software",
                },
                {
                  icon: "./images/menu-icons/coaching.svg",
                  title: "Coaching",
                  link: "/coaching-software",
                },
                {
                  icon: "./images/menu-icons/pin.svg",
                  title: "Acupunture",
                  link: "/acupuncture-software",
                },
                {
                  icon: "./images/menu-icons/pt.svg",
                  title: "Physical Therapy",
                  link: "/physical-therapy-software",
                },
                {
                  icon: "./images/menu-icons/apple.svg",
                  title: "Nutritionist",
                  link: "/nutritionist-software",
                },
                {
                  icon: "./images/menu-icons/bone.svg",
                  title: "Chiropractor",
                  link: "/chiropractor-software",
                },
                {
                  icon: "./images/menu-icons/pt.svg",
                  title: "Med Spa",
                  link: "/medical-spa-software",
                },
                {
                  icon: "./images/menu-icons/mental health.svg",
                  title: "Mental Health",
                  link: "/mental-health-software",
                },
              ]}
              isActive={activeDropDownIndex === 1}
              onClick={() => handleCardClick(1)}
            />
          </div>
          <div className="col-12 col-md-4">
            <BusinessCard
              type="Fitness"
              img="./images/Fitness-sales.webp"
              dropDown={[
                {
                  icon: "./images/menu-icons/pilates.svg",
                  title: "Pilates",
                  link: "/pilates-software",
                },
                {
                  icon: "./images/menu-icons/yoga.svg",
                  title: "Yoga",
                  link: "/yoga-software",
                },
                {
                  icon: "./images/menu-icons/ptrainer.svg",
                  title: "Personal Trainer",
                  link: "/personal-training-software",
                },
                {
                  icon: "./images/menu-icons/watch.svg",
                  title: "Gross Training",
                  link: "/cross-training-software",
                },
                {
                  icon: "./images/menu-icons/cycling.svg",
                  title: "Cycling",
                  link: "/cycling-software",
                },
                {
                  icon: "./images/menu-icons/dance.svg",
                  title: "Dance",
                  link: "/dancing-studio-software",
                },
                {
                  icon: "./images/menu-icons/gym.svg",
                  title: "Gym",
                  link: "/gym-software",
                },
                {
                  icon: "./images/menu-icons/fitnes.svg",
                  title: "All Fitness",
                  link: "/fitness-software",
                },
              ]}
              isActive={activeDropDownIndex === 2}
              onClick={() => handleCardClick(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
