import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Website() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Website Builder"
        desc="Create a beautiful, responsive page for your business that’s fully integrated with your Revyfy Fitness, Spa & Salon software."
        img="./images/Fearures Pages imgs/website/hero.png"
      />
      {/* Cards sections */}
      <LeftImgCard
        heading="Free Templates"
        desc="Select a template from our library that fits your business aesthetic. Or create your own theme to match your branding, including a logo, background, photos, fonts & colors. Your Revyfy Daily Deals, business hours, service menu & employee bios are always in sync with your site. You will never need an overpriced web developer again."
        img="./images/Fearures Pages imgs/website/img1.webp"
      />

      <RightImgCard
        heading="Custom Website Template"
        desc="Want a website template personalised for your business? Using your logo & photos, our talented in-house designers can create a theme to match your specific branding."
        img="./images/Fearures Pages imgs/website/img2.webp"
      />
      <LeftImgCard
        heading="Custom Logo"
        desc="Starting a new business? We can create a logo & recognisable branding to help your business thrive!"
        img="./images/Fearures Pages imgs/website/img3.webp"
      />
      <RightImgCard
        heading="Book Appointments Online"
        desc="Clients will be able to book their appointments with you 24/7. They’ll be able to look at their profile to see all their past services & purchases, as well as upcoming appointments scheduled."
        img="./images/Fearures Pages imgs/website/img4.webp"
      />
      <LeftImgCard
        heading="Online Shopping Cart"
        desc="Sell your products online and give your clients access to see the status of their orders. Revyfy is integrated with UPS, FedEx & USPS to automatically calculate the shipping costs for you."
        img="./images/Fearures Pages imgs/website/img5.webp"
      />
      <RightImgCard
        heading="Profiles for Service Providers"
        desc="Give your customers a personable experience by allowing your service providers to create profiles. Service profiles include a photo & bio for every service provider."
        img="./images/Fearures Pages imgs/website/img6.webp"
      />
      <LeftImgCard
        heading="Shipping is Easy"
        desc="Take the guesswork out of shipping your products. Revyfy automatically calculates shipping charges for everything in your inventory. We support shipping with UPS, FedEx & USPS."
        img="./images/Fearures Pages imgs/website/img7.webp"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
