import React from "react";
import "./rightToLeftFeatureContainer.css";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function RightToLeftFeatureContainer({
  smallHeading,
  mainHeading,
  desc,
  list,
  learnMoreLink,
  imgUrl,
}) {
  return (
    <div className="rightToLeftFeatureContainer py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 rightToLeftFeatureContainer-img">
            <img src={imgUrl} alt="" />
          </div>
          <div className="col-md-6 ps-md-5 ps-sm-0">
            <h4 className="rightToLeftFeatureContainer-smallHeading my-3">
              {smallHeading}
            </h4>
            <h2 className="rightToLeftFeatureContainer-mainHeading">
              {mainHeading}
            </h2>
            <p className="rightToLeftFeatureContainer-desc">{desc}</p>
            <div className="rightToLeftFeatureContainer-list">
              {list?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="rightToLeftFeatureContainer-list-item"
                  >
                    <CheckIcon className="list-item-icon" />
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>

            {learnMoreLink ? (
              <div className="rightToLeftFeatureContainer-learnMore">
                <Link to={learnMoreLink}>
                  <button className="btn jump-hover">
                    <span>Learn More</span>
                    <ChevronRightIcon />
                  </button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
