import React from "react";
import "./logoDropDown.css";
import LogoDropDownOption from "./LogoDropDownOption";

const LogoDropDown = ({ showDropDown }) => {
  return (
    <div
      className={`drop-down-menu logo-menu-card ${
        showDropDown === 1 ? "drop-down-menu-show" : ""
      }`}
    >
      <img src="./images/menu-icons/triangle.svg" className="tip" alt="" />
      <div className="row drop-down-options p-3 logo-options">
        <div className="p-0">
          <LogoDropDownOption
            icon="./images/menu-icons/home.svg"
            title="Home"
            pageLink="/"
          />
          <LogoDropDownOption
            icon="./images/menu-icons/book.svg"
            title="Book with Revyfy"
            pageLink="/"
          />
          <LogoDropDownOption
            icon="./images/menu-icons/pro.svg"
            title="Revyfy Pro Blog"
            pageLink="/"
          />
        </div>
      </div>
    </div>
  );
};

export default LogoDropDown;
