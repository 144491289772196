import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";

export default function Reports() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Reports"
        desc="While others brag about how many reports you can run with their system, we take pride on our simple, clear and concise reports. Get all the reports you need on any device."
        img="./images/Fearures Pages imgs/reports/hero.png"
      />
      {/* Cards sections */}

      <LeftImgCard
        heading="Dashboard"
        desc="At a glance, view the status of your sales, appointments, service providers and much more right from the dashboard. Revyfy's dashboard is accessible from any device so you can keep an eye on your business from anywhere."
        img="./images/Fearures Pages imgs/reports/img1.webp"
      />

      <RightImgCard
        heading="Integration with QuickBooks"
        desc="Sync your Revyfy sales transactions with Intuit QuickBooks Online. Revyfy works alongside QuickBooks to keep your information up to date for your small business payroll , taxes & inventory."
        img="./images/Fearures Pages imgs/reports/img3.gif"
      />

      <LeftImgCard
        heading="Integration with Xero"
        desc="Sync your Revyfy sales transactions with Xero. Revyfy works alongside Xero to keep your information up to date for taxes, payroll & inventory."
        img="./images/Fearures Pages imgs/reports/img3.gif"
      />

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
