import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import LoginForm from "../../components/LoginForm/LoginForm";

export default function Login() {
  return (
    <>
      <Navbar />
      <LoginForm />
    </>
  );
}
