import React from "react";
import "./customer.css";
import CustomerCarouselCard from "./CustomerCarouselCard";

export default function Customer({ smallHeading, heading }) {
  return (
    <div className="pb-5 mb-5">
      <div className="container my-5 pt-5">
        <div className="row my-5">
          <div className="col text-center">
            <h5 style={{ fontSize: "16px", textTransform: "uppercase" }}>
              {smallHeading}
            </h5>
            <h1 className="fw-bold" style={{ fontSize: "36px" }}>
              {heading}
            </h1>
          </div>
        </div>
      </div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="true"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner ">
          <CustomerCarouselCard
            profile="./images/image2.jpg"
            review="We discovered Revyfy and the business felt like it started to run itself. It just streamlines the whole process so that my books are always full."
            designation=" Owner of Bloom Healing Botanicals & Spa"
            name="Rebecca Mattice"
          />
          <CustomerCarouselCard
            profile="./images/image14.jpg"
            review="Revyfy has allowed me to focus on coaching. I don't have to think about the app so I can just focus on helping my coaches, helping my clients with the business"
            designation="Owners of Salon in Los Angeles, CA"
            name="Matt & Harlee Showalter"
          />
          <CustomerCarouselCard
            profile="./images/image.jpg"
            review="We discovered Revyfy and the business felt like it started to run itself. It just streamlines the whole process so that my books are always full."
            designation="Owners of Salon in Los Angeles, CA"
            name="Matt & Harlee Showalter"
          />
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
