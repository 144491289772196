import React from "react";
import "./serviceCard.css";

export default function ServiceCard({ img, text, para }) {
  return (
    <>
      <div className="service-card">
        <div className="service-card-body text-center">
          <div className="service-card-img">
            <img src={img} alt="" />
          </div>
          <h5 className="service-card-title mt-2">{text}</h5>
          <p className="service-card-text">{para}</p>
        </div>
      </div>
    </>
  );
}
