import React from "react";
import "./customerCarouselCard.css";

export default function CustomerCarouselCard({
  profile,
  review,
  designation,
  name,
}) {
  return (
    <>
      <div className="carousel-item active">
        <div className="carousal__text container px-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-4 carousel-item-img">
              <img src={profile} className="d-block " alt="" />
            </div>
            <div className="col-12 col-md-12 col-lg-8 d-flex px-5 justify-content-center flex-column carousel-item-content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                fill="currentColor"
                style={{ color: "#708d81" }}
                className="bi bi-quote"
                viewBox="0 0 16 16"
              >
                <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
              </svg>
              <div className="review-container">
                <h2 className="mb-4 review-text">{review}</h2>
                <div className="review-writer">
                  <p>
                    {designation}
                    <span></span>
                  </p>
                  <p style={{ color: "#E0E7FF" }}>{name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
