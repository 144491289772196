import React from "react";
import "./onlineStore.css";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import LeftToRightFeatureContainer from "../../../components/LeftToRightFeatureContainer/LeftToRightFeatureContainer";
import CardWithImage from "../../../components/CardWithImage/CardWithImage";
import FullWidthCard from "../../../components/FullWidthCard/FullWidthCard";
import ServiceCard from "../../../components/service card/ServiceCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import BadgeIcon from "@mui/icons-material/Badge";

export default function OnlineStore() {
  return (
    <>
      {/* NavBar */}
      <Navbar />

      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Everything You Need To Sell Online"
        desc="Your Online Store is fully integrated with Revyfy’s booking & payment processing platform. Get everything you need to market your store, process payments, manage inventory & ship products."
        img="./images/Fearures Pages imgs/online-store/hero.png"
      />

      {/* Sell More Section */}
      <div className="container p-5 sellMore">
        <h3>Sell More. Sell Online.</h3>
        <p className="sellMore-text">
          Earn money fast by selling everything online: products, services,
          classes, packages, memberships and gift cards. Keep 100% of your
          online sales - we don't charge commissions! Clients can buy from your
          Revyfy booking page or from your own website via the Revyfy Widget.
        </p>
        <div className="row sellMore-icons-container">
          <div className="col-6 col-md-2 sellMore-icon-box">
            <ShoppingCartIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
          <div className="col-6 col-md-2 sellMore-icon-box">
            <CalendarMonthIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
          <div className="col-6 col-md-2 sellMore-icon-box">
            <SchoolIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
          <div className="col-6 col-md-2 sellMore-icon-box">
            <CardGiftcardIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
          <div className="col-6 col-md-2 sellMore-icon-box">
            <BadgeIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
          <div className="col-6 col-md-2 sellMore-icon-box">
            <CardGiftcardIcon className="sellMore-icon" />
            <p>Products</p>
          </div>
        </div>
      </div>

      <LeftToRightFeatureContainer
        smallHeading="REVYFY PAY LATER"
        mainHeading="Expand your business with Buy Now, Pay Later"
        desc=" Offer your customers interest-free payment installments and get paid in full, instantly."
        list={[
          "Increase higher dollar checkouts",
          "No monthly fees, or start-up cost",
          "Convert high-intent shoppers into loyal customers",
        ]}
        learnMoreLink="/online-store"
        imgUrl="./images/Fearures Pages imgs/online-store/img1.webp"
      />

      <LeftImgCard
        heading="Built-In Marketing Tools"
        desc="Promote your online store with powerful email and text marketing. Include discount codes in your messaging to boost sales. Bring in new clients by showcasing your storefront on Revyfy.com and on the Revyfy App."
        img="./images/Fearures Pages imgs/online-store/img2.webp"
      />

      <RightImgCard
        heading="Easy & Secure Online Payments"
        desc="Sign up for Revyfy Merchant Services and accept all major credit cards safely and securely. Capture credit card details to simplify repeat purchases."
        img="./images/Fearures Pages imgs/online-store/img3.webp"
      />

      <LeftImgCard
        heading="Customizable Shipping Options"
        desc="Choose your preferred carrier and Revyfy will automatically calculate shipping costs and allow for easy printing of shipping labels. Boost online sales by offering free shipping or flat shipping rates. Offer curbside pickup to support social distancing."
        img="./images/Fearures Pages imgs/online-store/img4.webp"
      />

      <RightImgCard
        heading="Automated Inventory Management"
        desc="Upload images for each product and set a business cost and a sales price. Our system automatically detects low quantities, so you can create and send purchase orders in a timely fashion."
        img="./images/Fearures Pages imgs/online-store/img5.webp"
      />

      <LeftImgCard
        heading="Detailed Analytics"
        desc="Get an inside look into your top-selling product categories (ex. yoga mats ), identify slow sellers, and track sold, lost, damaged & expired products."
        img="./images/Fearures Pages imgs/online-store/img6.webp"
      />

      {/* Get Paid Section */}
      <div className="get-paid">
        <div className="container">
          <HeadingSection
            title="ONLINE STORE"
            heading="Manage Your Online Store From Any Device"
            details="Market and manage your store from your computer or from your mobile device when you’re out and about."
          />

          <div className="row gy-4 get-paid-payment-features">
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-store/card1.webp"
                heading="Run Your Store From Your Front Desk"
                text="Manage your online store, schedule clients and process payments all at the same time, within the same software."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-store/card2.webp"
                heading="Sell While Working With Clients"
                text="Suggest helpful products while you're with your clients. They can safely purchase from their Revyfy App right then and there."
              />
            </div>
            <div className="col-md-4">
              <CardWithImage
                image="./images/Fearures Pages imgs/online-store/card3.webp"
                heading="Manage Online Sales On The Go"
                text="Update products and watch over your sales from anywhere. The Revyfy Shopping Cart works on iPhone and Android mobile devices."
              />
            </div>

            {/* ONLINE STORE Card  */}
            <div>
              <FullWidthCard
                smallHeading="ONLINE STORE"
                mainHeading="An Easier Way to Take Payments Online"
                list={[
                  "Take your business online & never miss a sale using your online store",
                  "Pre-pay for services & sell products, gift cards, memberships, & packages 24/7",
                  "Capture credit cards for online purchases safely & securely",
                ]}
                imgUrl="./images/Fearures Pages imgs/card-processing/img2.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="everything-heading">
        <h4>Everything You Need To Manage Your E-Commerce Store</h4>
      </div>
      <div className="container">
        <div className="row gy-4 service-card-container">
          <div className="col-md-5">
            <ServiceCard
              img="./images/Fearures Pages imgs/card-processing/icon1.svg"
              text="Fully Integrated E-Commerce Platform"
              para="Our online store is much more than an e-commerce website. It's an online platform that has all the tools you need to manage your online business."
            />
          </div>
          <div className="col-md-5">
            <ServiceCard
              img="./images/Fearures Pages imgs/card-processing/icon2.svg"
              text="Fully Integrated E-Commerce Platform"
              para="Our online store is much more than an e-commerce website. It's an online platform that has all the tools you need to manage your online business."
            />
          </div>
          <div className="col-md-5">
            <ServiceCard
              img="./images/Fearures Pages imgs/card-processing/icon3.svg"
              text="Fully Integrated E-Commerce Platform"
              para="Our online store is much more than an e-commerce website. It's an online platform that has all the tools you need to manage your online business."
            />
          </div>
          <div className="col-md-5">
            <ServiceCard
              img="./images/Fearures Pages imgs/card-processing/icon4.svg"
              text="Fully Integrated E-Commerce Platform"
              para="Our online store is much more than an e-commerce website. It's an online platform that has all the tools you need to manage your online business."
            />
          </div>
        </div>
      </div>

      <Trail />
      <Footer />
    </>
  );
}
