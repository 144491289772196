import React from "react";
import FeaturesPagesHero from "../../../components/FeaturesPagesHero/FeaturesPagesHero";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Trail from "../../../components/Trail/Trail";
import LeftImgCard from "../../../components/FeaturesPagesCard/LeftImgCard";
import RightImgCard from "../../../components/FeaturesPagesCard/RightImgCard";
import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import Accordion from "../../../components/Accordion/Accordion";

export default function PayDesk() {
  return (
    <>
      {/* NavBar */}
      <Navbar />
      {/* Hero Section */}
      <FeaturesPagesHero
        heading="Your New Front Desk"
        desc="A fully integrated dual screen point of sale that offers your customers a safe, transparent, and secure experience."
        img="./images/Fearures Pages imgs/paydesk/hero.png"
      />
      {/* Cards sections */}
      <HeadingSection
        heading="Choose The Right Pay Desk For You"
        details="*Pay Desk Plus and Complete include an additional £100 discount when signing up for a new 1 year merchant account."
      />

      <RightImgCard
        heading="Fully Integrated with Revyfy"
        desc="Book appointments, accept payments, and run everything straight from the Revyfy Pay Desk. It's business as usual as the Revyfy Pay Desk runs on the Revyfy Pro App."
        img="./images/Fearures Pages imgs/paydesk/img1.webp"
      />
      <LeftImgCard
        heading="COVID-Clean Keeps Everyone Safe"
        desc="Choose from a set of built in sanitary prompts to keep your customers and employees safe like 'Apply Hand Sanitizer'. COVID-Clean sanitary prompts give your customers more confidence in your business and its safety precautions."
        img="./images/Fearures Pages imgs/paydesk/img2.webp"
      />
      <RightImgCard
        heading="Dual Screens for Business Security"
        desc="The Revyfy Pay Desk offers a screen for business and a screen for your customers. No need to hand your device over for clients to tip and sign - a double screen ensures you and your client have the information you need, right in front of you."
        img="./images/Fearures Pages imgs/paydesk/img3.webp"
      />

      <LeftImgCard
        heading="Transparent Customer Experience"
        desc="Customers can view a breakdown of their checkout, apply a tip, sign, and pay - all from the designated customer screen."
        img="./images/Fearures Pages imgs/paydesk/img4.gif"
      />
      <RightImgCard
        heading="Market with Daily Deals"
        desc="Promote your brand and your services during checkout. Showcase your logo, images of your work, or even advertise your Daily Deals to get a last minute sale."
        img="./images/Fearures Pages imgs/paydesk/img5.gif"
      />
      <LeftImgCard
        heading="Customise Your Look"
        desc="Offer your customers a light mode or dark mode to match your brand as they tip, sign & pay."
        img="./images/Fearures Pages imgs/paydesk/img6.gif"
      />
      <RightImgCard
        heading="Perfect Size for Your Front Desk"
        desc="Make a statement with your front desk. Both customer and business facing screens have a 15.6 inch display with a 1920 x 1080 HD Resolution."
        img="./images/Fearures Pages imgs/paydesk/img7.webp"
      />
      {/* FAQ */}
      {/* Accordion */}
      <div
        className="businessTypesAccordion"
        style={{ backgroundColor: "var( --very-light-gray)" }}
      >
        <Accordion
          heading="Revyfy Pay Desk FAQ"
          data={[
            {
              ques: "Does the Pay Desk support WiFi?",
              ans: "Yes, it supports 2.4 GHz WiFi networks.",
            },
            {
              ques: "Does the Pay Desk work with the Revyfy USB Credit Card Swiper?",
              ans: "Yes, the Revyfy USB Credit Card Swiper is compatible.",
            },
            {
              ques: "Does the Pay Desk work with external keyboards?",
              ans: "Yes, USB or Bluetooth keyboards are compatible.",
            },
            {
              ques: "Does the Pay Desk come with a warranty?",
              ans: "Yes, Revyfy offers a one year warranty.",
            },
            {
              ques: "Does the Handheld Scanner work with barcodes and QR codes?",
              ans: "Yes, the Handheld Scanner works for both barcodes and QR codes.",
            },
            {
              ques: "Does the Check-In App work on Pay Desk?",
              ans: "No, the Check-In App is only compatible with iOS and Android tablets.",
            },
            {
              ques: "Does the Pay Desk work with the wireless receipt printer?",
              ans: "Yes, the wireless receipt printer works on Pay Desk.",
            },
            {
              ques: "Does the Check-In App work on Pay Desk?",
              ans: "No, the Check-In App is only compatible with iOS and Android tablets.",
            },
            {
              ques: "Does the Check-In App work on Pay Desk?",
              ans: "No, the Check-In App is only compatible with iOS and Android tablets.",
            },
          ]}
        />
      </div>

      {/* Trail Component */}
      <Trail />

      {/* Footer Component */}
      <Footer />
    </>
  );
}
