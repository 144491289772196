import React from "react";
import "./contactUs.css";
import ContactUsCard from "./ContactUsCard";

export default function ContactUs() {
  return (
    <div className="container-fluid contactUs">
      <div className="container-fluid contactUs-top-section">
        <div className="triangle left-triangle">
          <img src="./images/pink-triangle.svg" alt="" />
        </div>
        <div className="contactUs-heading">
          <h2>Contact Us</h2>
          <p>Get in touch and let us know how we can help.</p>
        </div>
        <div className="triangle right-triangle">
          <img src="./images/voilet-triangle.svg" alt="" />
        </div>
      </div>
      <div className="container-fluid contactUs-bottom-section">
        <div className="row gy-4">
          <div className="col-md-3">
            <ContactUsCard
              icon="./images/home.svg"
              title="Sales"
              duration="Monday 9 AM to Friday 5 PM"
              itemsList={[
                {
                  phoneIcon: true,
                  text: "0123456789",
                  phoneNumber: "0123456789",
                },
                {
                  phoneIcon: true,
                  text: "Enterprise 0123456789",
                  phoneNumber: "0123456789",
                },
                {
                  text: "Request a Demo or Phone Call",
                  linkTo: "/contact-demo",
                },
                { text: "Contact Sales", linkTo: "/contact-sales" },
                { text: "Chat Now", linkTo: "/" },
              ]}
            />
          </div>
          <div className="col-md-3">
            <ContactUsCard
              icon="./images/wheel.svg"
              title="Support"
              duration="Available 24/7, all day and all night"
              itemsList={[
                {
                  phoneIcon: true,
                  text: "0123456789",
                  phoneNumber: "0123456789",
                },
                {
                  text: "View Webinars",
                  linkTo: "/",
                },
                {
                  text: "Support Articles",
                  linkTo: "/",
                },
                {
                  emailIcon: true,
                  text: "support@revyfy.com",
                  email: "support@revyfy.com",
                },

                { text: "Chat Now", linkTo: "/" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
