import React from "react";
import "./contactSalesForm.css";

export default function ContactSalesForm() {
  return (
    <section className="contactSalesForm">
      <div className="container py-5">
        <h1 className="contactSalesForm-heading">
          Get in touch with our awesome sales team.
        </h1>
        <h4>
          <img
            src="./images/flag-uk.svg"
            alt=""
            className="contactSalesForm-heading-img"
          />
          <span> United Kingdom: 0123456789</span>
        </h4>
        <form action="" className="p-3 contactSalesForm-form">
          <div>
            <div className="row px-3">
              <div className="col-md-6 input-box">
                <label htmlFor="fName" className="input-box-label">
                  First Name:<span className="astarisk">*</span>
                </label>
                <input
                  type="text"
                  id="fName"
                  placeholder="Enter First Name"
                  required
                />
                <div className="error-msg">
                  Please complete this required field.
                </div>
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="lName" className="input-box-label">
                  Last Name:<span className="astarisk">*</span>
                </label>
                <input
                  type="text"
                  id="lName"
                  placeholder="Last Name"
                  required
                />
                <div className="error-msg">
                  Please complete this required field.
                </div>
              </div>

              <div className="col-md-6 input-box">
                <label htmlFor="phoone" className="input-box-label">
                  Phone:<span className="astarisk">*</span>
                </label>
                <input
                  type="text"
                  id="phoone"
                  placeholder="Enter Phone Number"
                  required
                />
                <div className="error-msg">
                  Please complete this required field.
                </div>
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="email" className="input-box-label">
                  Email:<span className="astarisk">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="you@example.com"
                  required
                />
                <div className="error-msg">
                  Please complete this required field.
                </div>
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="noOflocation" className="input-box-label">
                  Number of Location:<span className="astarisk">*</span>
                </label>
                <select name="noOflocation" id="noOflocation">
                  <option value="1">1</option>
                  <option value="2-4">2-4</option>
                  <option value="5-10">5-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21-50">21-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101+">101+</option>
                </select>
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="company" className="input-box-label">
                  Company Name:
                </label>
                <input type="text" id="company" required />
                <div className="error-msg">
                  Please complete this required field.
                </div>
              </div>
              <div className="col-md-6 input-box">
                <label htmlFor="hear-feedback" className="input-box-label">
                  How did you hear about Revyfy?
                </label>
                <select name="hear-feedback" id="hear-feedback">
                  <option value="A Friend">A Friend</option>
                  <option value="Android Marketplace">
                    Android Marketplace
                  </option>
                  <option value="Apple app store">Apple app store</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Google, yahoo, bing">
                    Google, yahoo, bing
                  </option>
                </select>
              </div>

              <div className="col-md-6 input-box">
                <label htmlFor="chat" className="input-box-label">
                  What do you want to chat about?
                  <span className="astarisk">*</span>
                </label>
                <select name="chat" id="chat">
                  <option disabled value="Please Select">
                    Please Select
                  </option>
                  <option value="Need Pricing Information">
                    Need Pricing Information
                  </option>
                  <option value="Would like to schedule a Demo">
                    Would like to schedule a Demo
                  </option>
                  <option value="General (will write in comments below)">
                    General (will write in comments below)
                  </option>
                </select>
              </div>
              <div className="col-md-4 input-box">
                <label htmlFor="Business" className="input-box-label">
                  Business Type:
                </label>
                <div className="check-box-div">
                  <input type="checkbox" id="Beauty" value="Beauty" />
                  <label htmlFor="Beauty">Beauty</label>
                </div>
                <div className="check-box-div">
                  <input type="checkbox" id="Fitness" value="Fitness" />
                  <label htmlFor="Fitness">Fitness</label>
                </div>
                <div className="check-box-div">
                  <input type="checkbox" id="Wellness" value="Wellness" />
                  <label htmlFor="Wellness">Wellness</label>
                </div>
                <div className="check-box-div">
                  <input type="checkbox" id="Other" value="Other" />
                  <label htmlFor="Other">Other</label>
                </div>
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="message" className="input-box-label">
                  Message
                </label>
                <textarea type="text" id="message" />
              </div>

              <div className="send-btn">
                <button type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
